<div class="containerBanner">
  <div class="menu-container">
    <h1>Workstations<span>({{this.totalWorkstation}})</span></h1>
    <div class="workstation-menu d-flex justify-content-between" style="margin-bottom: 15px;">
      <div class="search-menu">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
          class="search-icon">
          <g opacity="0.4">
            <circle cx="11.0795" cy="11.3768" r="7.62168" transform="rotate(-45 11.0795 11.3768)" stroke="#092C4C"
              stroke-width="1.72802" stroke-linecap="round" />
            <path d="M16.6416 16.9393L19.8465 20.1443" stroke="#092C4C" stroke-width="1.72802" stroke-linecap="round" />
          </g>
        </svg>
        <input type="search" placeholder="Search workstations.." [(ngModel)]="searchQuery"
        (keyup.enter)="onSearchInventory()" />
      @if(searchQuery){
      <img class="search-close" style="cursor: pointer" src="../../../assets/closeIcon.png" alt=""
        (click)="clearSearch()" />}
      </div>
      @if(showWorkstationButton ){
      <button class="d-flex align-items-center justify-content-center add-inv" type="button" data-toggle="modal"
        data-target="#addWorkstationModal" style="margin: 0;" (click)="resetClose()">
        <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 32 32"
          style="margin-right: 8px">
          <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
        </svg>
        Workstation
      </button>
      }
    </div>

    <!-- Workstation Listing -->
    <div >
      @if(!isShimmerLoading) {
      <table style="overflow-x: auto;max-height: 510px;height: 510px;">
        <thead>
          <tr>
            <th scope="col">WORKSTATION NAME</th>
            <th scope="col">ASSIGNED TO</th>
            <th scope="col">ASSIGNED ON</th>
            <th scope="col">PROCESSOR</th>
            <th scope="col">RAM VALUE</th>
            <th scope="col">GRAPHIC CARD</th>
            <th scope="col">WINDOWS</th>
            <th scope="col">LICENCED</th>
            <th scope="col">MONITORS</th>
            <th scope="col">MONITORS DETAILS</th>
            <th scope="col">HEADPHONE</th>
            <th scope="col">KEYBOARD</th>
            <th scope="col">MOUSE</th>
            <th scope="col">CAMERA</th>
            <th scope="col">SPEAKER</th>
          </tr>
        </thead>
        <tbody>
          @for (workstations of workstation; track $index) {
          <tr>
            <td  (click)="getSingleworkstation(workstations._id)">
              <div class="d-flex align-items-center">
                <div class="inventory-img" data-target="#imagePreviewModal" data-toggle="modal"
                  (click)="openPreviewSlider($index)">
                  <img
                    [src]="workstations.image ? workstations.image : '../../../assets/inventories/computer-system.jpg' "
                    alt="workstation" style="width: 60px;height:60px;cursor: pointer;" />

                </div>
                <div data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
                  (click)="openWorkstationDetail($index)">
                  <p>{{workstations.name}}</p>
                </div>
              </div>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.assignedTo ? workstations.assignedTo : " - "}}
                </p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.assignedOn ? workstations.assignedOn : " - "}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.processor}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.ramValue}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.graphicCard}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.windows}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p [style.color]="workstations.licensed ? 'green' : 'red'">
                {{ workstations.licensed ? 'licensed' : 'not licensed' }}
              </p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;text-align: center;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p style="text-align: center;">{{workstations.monitors}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.monitorDetails[0]!== "null" ? workstations.monitorDetails[0] : '-' }}</p>
              <p>{{workstations.monitorDetails[1]!== "null" ? workstations.monitorDetails[1] : ''}}</p>
              <p>{{workstations.monitorDetails[2]!== "null" ? workstations.monitorDetails[2] : ''}}</p>
              <p>{{workstations.monitorDetails[3]!== "null" ? workstations.monitorDetails[3] : ''}}</p>
              <p>{{workstations.monitorDetails[4]!== "null" ? workstations.monitorDetails[4] : ''}}</p>
              <p>{{workstations.monitorDetails[5]!== "null" ? workstations.monitorDetails[5] : ''}}</p>
            </td>

            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.headPhone ? workstations.headPhone : ' - '}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)">
              <p>{{workstations.keyboard ? workstations.keyboard : ' - '}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)"> 
              <p>{{workstations.mouse  ? workstations.mouse : ' - '}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)"> 
              <p>{{workstations.camera  ? workstations.camera : ' - '}}</p>
            </td>
            <td data-target="#workstationDetailModal" data-toggle="modal" style="cursor: pointer;"
            (click)="openWorkstationDetail($index);getSingleworkstation(workstations._id)"> 
              <p>{{workstations.speaker  ? workstations.speaker : ' - '}}</p>
            </td>
          </tr>
          
          }
        </tbody>
        @if(workstation.length === 0){
          <app-no-data></app-no-data>
          }
      </table>
    }
      @if(isShimmerLoading) {  
        @for (workstations of shimmerLoadingValue; track workstations; let
        i = $index) { 
        <span class="d-flex">
          <div class="shimmer-card">
            <div class="shimmerBG media"></div>
            <div class="p-32">
              <div class="shimmerBG title-line"></div>
              <div class="shimmerBG title-line end"></div>
      
              <div class="shimmerBG content-line m-t-24"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line"></div>
              <div class="shimmerBG content-line end"></div>
            </div>
          </div>
        </span>
        } }
    </div>

    <!-- Pagination -->
     @if(workstation.length > 0){
  <nav aria-label="Page navigation example" style="position: absolute;left: 50%;bottom: 2%;">
    <ul class="pagination" style="margin-top: 10px;">
      <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
        <a class="page-link" href="javascript:void(0)" aria-label="Previous"
          (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
            <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z" />
          </svg>
        </a>
      </li>
      @for(page of visiblePages; track page){
      <li class="page-item" [ngClass]="{
          active: currentPage === page,
          hidden: !isPageVisible(page)
        }">
        <a class="page-link" href="javascript:void(0)" (click)="pageChanged(page)">{{ page }}</a>
      </li>
      }
  
      <li class="page-item" [ngClass]="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="
            currentPage !== totalPages ? pageChanged(currentPage + 1) : null
          ">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
            <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z" />
          </svg>
        </a>
      </li>
    </ul>
  </nav>
}
  </div>
</div>

<!-- Add New Workstation Modal -->
<div class="modal fade" id="addWorkstationModal" tabindex="-1" role="dialog" aria-labelledby="addWorkstationModalTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered main-width-content" role="document">
    <div class="modal-content">
      <div class="modal-header addInventory-head">
        <h5 class="modal-title" id="addWorkstationModalTitle">
          {{isEdit ? 'Edit Workstation' : ' Add New Workstation'}}
        </h5>
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body addInventory-body">
        @if (currentStep !== 3) {
        <ul class="add-inv-menu">
          <li [ngClass]="{'active': currentStep === 1, 'completed': configurationForm.valid}"
            (click)="navigateToStep(1)">
            Configuration
          </li>
          <li [ngClass]="{'active': currentStep === 2, 'completed': currentStep > 1}">
            <svg class="mg-space" width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5"
                d="M6.58887 3.69341C6.75553 3.78963 6.75553 4.03019 6.58887 4.12642L0.68225 7.5366C0.515584 7.63283 0.307251 7.51255 0.307251 7.3201L0.307251 0.499725C0.307251 0.307275 0.515584 0.186994 0.682251 0.283219L6.58887 3.69341Z"
                fill="#25252B" />
            </svg>
            Accessories
          </li>
       
          <li [ngClass]="{'active': currentStep === 3, 'completed': currentStep > 1}">

          </li>
        </ul>
      }

        <!-- Configuration -->
        @if(currentStep === 1){
        <div class="product-info-container d-flex justify-content-between">
          <form class="d-flex justify-content-between align-items-center" [formGroup]="configurationForm"
            (ngSubmit)="onSubmitConfiguration()">

            <div class="prod-col" style="margin: 0 122px;">
              <div class="product-image-upload">
                <div class="upload-content">
                  @if (selectedImages.length > 0) {
                  <ng-container>
                    <img class="previewImg" [src]="previewImagesUrl[0]" alt="">
                  </ng-container>
                  }
                  @if (selectedImages.length === 0) {
                  <ng-container>
                    <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="21.0079" cy="20.397" r="20.2278" fill="#FDCC27" />
                      <path
                        d="M16.584 27.4629C15.7892 27.4043 14.8021 27.5967 13.8483 27.3708C11.9409 26.9191 10.8115 25.6475 10.41 23.7819C10.0168 21.9497 10.6442 20.4355 12.1584 19.3061C12.3676 19.1472 12.4429 19.03 12.3759 18.7707C11.8656 16.5956 13.7563 14.6965 15.9398 15.1985C16.1824 15.257 16.2995 15.2152 16.425 14.9893C17.7134 12.6803 20.1395 11.5342 22.7329 11.986C25.1255 12.4043 27.0497 14.3702 27.4847 16.88C27.5349 17.1561 27.6269 17.2732 27.8947 17.3401C30.2789 17.9257 31.9856 20.4271 31.6844 22.8449C31.3498 25.5303 29.2416 27.4629 26.6565 27.4629C25.3598 27.4629 24.0631 27.4545 22.7664 27.4712C22.4903 27.4712 22.415 27.3876 22.415 27.1199C22.4317 25.8064 22.4234 24.5013 22.415 23.1879C22.415 22.9285 22.4736 22.8114 22.758 22.8282C23.1177 22.8533 23.4858 22.8449 23.8456 22.8282C24.3726 22.8031 24.5734 22.435 24.2722 22.0083C23.3353 20.6949 22.3815 19.3814 21.4362 18.0763C21.1601 17.6999 20.8339 17.6999 20.5578 18.0763C19.6041 19.3814 18.6587 20.6949 17.7217 22.0083C17.4206 22.435 17.613 22.8031 18.1484 22.8282C18.5249 22.8449 18.9013 22.8365 19.2778 22.8282C19.4786 22.8282 19.5873 22.8616 19.579 23.1042C19.5706 24.4679 19.5706 25.8399 19.579 27.2035C19.579 27.4461 19.4702 27.4712 19.2694 27.4712C18.4328 27.4545 17.5963 27.4629 16.584 27.4629Z"
                        fill="white" />
                    </svg>
                  </ng-container>
                  }
                  <h3>Upload Photo</h3>
                </div>
                <input type="file" class="form-control" (change)="onSelectImage($event)" 
                  accept="image/png, image/jpeg" />
              </div>
              <p>
                (Recommended Ratio: 9:9)
              </p>
              <h4>Add Multiple Images</h4>
              <p>Max 4 Images</p>
              <div class="d-flex align-items-center justify-content-center" style="margin-top: 20px;">
                @if (selectedImages.length
                < 5) { <div class="image-upload-container"
                  [ngStyle]="{ cursor: selectedImages.length === 0 ? 'not-allowed' : 'pointer' }"
                  style="border: 1px solid #e7e7e7;border-radius: 4px;position:relative;margin-right:20px;"
                  [ngStyle]="{ opacity: selectedImages.length === 0 ? 0.7 : 1 }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 32 32"
                    style="font-size:20px;position: absolute;top: 30%;left:30%;cursor: pointer;">
                    <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
                  </svg>
                  <input type="file" class="form-control" style="opacity: 0;width:60px;height:65px;cursor: pointer;"
                    (change)="onSelectMultipleImages($event)" multiple accept="image/png, image/jpeg"
                    [disabled]="selectedImages.length === 0" />

              </div>
              }
              @for (img of previewImagesUrl.slice(1); track img; let i =$index; ) {
              <ng-container>
                <div class=" multiple-img">
                  <img [src]="img" alt="" style="width: 50px;height: 50px;" />
                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"
                    (click)="removeImage(i + 1)">
                    <circle cx="13.4069" cy="13.9219" r="13" fill="#353535" />
                    <path d="M10.2818 10.7974L16.5319 17.0475" stroke="white" stroke-width="1.5"
                      stroke-linecap="round" />
                    <path d="M10.2818 17.0474L16.5319 10.7972" stroke="white" stroke-width="1.5"
                      stroke-linecap="round" />
                  </svg>
                </div>
              </ng-container>
              }
            </div>
        </div>

        <div style="width: 60%;">
          <div class="form-row">
            <div class="form-group col-md-6"
              [ngClass]="{'has-error': configurationForm.get('workstationName').invalid && (configurationForm.get('workstationName').dirty || configurationForm.get('workstationName').touched || submitted)}">
              <label for="workstationName" class="form-label asterisk-lbl">Workstation Name</label>
              <input type="text" id="workstationName" class="form-control" formControlName="workstationName" />
              @if(configurationForm.get('workstationName').errors?.maxlength){
              <div style="color:red">Workstation Name cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-6"
              [ngClass]="{'has-error': configurationForm.get('assignTo').invalid && (configurationForm.get('assignTo').dirty || configurationForm.get('assignTo').touched || submitted)}">
              <label for="assignTo" class="form-label">Assign to</label>
              <mat-form-field>
                <mat-select formControlName="assignTo" placeholder="select">
                  @for (user of users; track user) {
                  <mat-option [value]="user.value">{{user.viewValue}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <!-- <svg  (click)="removeAssignee(workstation)"
              style="position: absolute;
              right: -3%;
              top: 50%;
              width: 20px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#ff0000" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"/></svg> -->
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6"
              [ngClass]="{'has-error': configurationForm.get('processor').invalid && (configurationForm.get('processor').dirty || configurationForm.get('processor').touched || submitted)}">
              <label for="processor" class="form-label asterisk-lbl">Processor</label>
              <input type="text" id="processor" class="form-control" formControlName="processor" />

            </div>
            <div class="form-group col-md-6"
              [ngClass]="{'has-error': configurationForm.get('ramValue').invalid && (configurationForm.get('ramValue').dirty || configurationForm.get('ramValue').touched || submitted)}">
              <label for="ramValue" class="form-label asterisk-lbl">Ram Value</label>
              <input type="text" id="ramValue" class="form-control" formControlName="ramValue" />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6"
              [ngClass]="{'has-error': configurationForm.get('graphicCard').invalid && (configurationForm.get('graphicCard').dirty || configurationForm.get('graphicCard').touched || submitted)}">
              <label for="condition" class="form-label asterisk-lbl">Graphics Card</label>
              <input type="text" id="graphicCard" class="form-control" formControlName="graphicCard" />
            </div>
            <div class="form-group col-md-6"
              [ngClass]="{'has-error': configurationForm.get('version').invalid && (configurationForm.get('version').dirty || configurationForm.get('version').touched || submitted)}">
              <label for="version" class="form-label asterisk-lbl">Windows Version</label>
              <input type="text" id="version" class="form-control" formControlName="version" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6"
              [ngClass]="{'has-error': configurationForm.get('isLicensed').invalid && (configurationForm.get('isLicensed').dirty || configurationForm.get('isLicensed').touched || submitted)}">
              <label class="labelish asterisk-lbl">Is Windows Licensed</label>
              <div class="rd-button d-flex">
                <div class="d-flex justify-content-between align-items-center">
                  <input id="yes" type="radio" value="yes" formControlName="isLicensed" />
                  <label for="yes">Yes</label>
                </div>

                <div class="d-flex justify-content-between align-items-center" style="margin-left: 90px;">
                  <input id="no" type="radio" value="no" formControlName="isLicensed" />
                  <label for="no">No</label>
                </div>

              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button type="submit" class="btn btn-warning">Next</button>
          </div>
        </div>

        </form>
      </div>
      }

      <!-- Acessories -->
      @if(currentStep === 2){
      <div class="product-info-container">
        <form [formGroup]="accessoriesForm" (ngSubmit)="onSubmitAccessories()">
          <div class="form-row">
            <div class="form-group col-md-4" 
            [ngClass]="{'has-error': accessoriesForm.get('monitor1').invalid && (accessoriesForm.get('monitor1').dirty || accessoriesForm.get('monitor1').touched || submitted)}"
            >
              <label for="monitor1" class="form-label asterisk-lbl">Monitor 1</label>
              <input type="text" id="monitor1" class="form-control" formControlName="monitor1" />
              @if(accessoriesForm.get('monitor1').errors?.maxlength){
              <div style="color:red">Monitor name cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4"  
            [ngClass]="{'has-error': accessoriesForm.get('monitor2').invalid && (accessoriesForm.get('monitor2').dirty || accessoriesForm.get('monitor2').touched || submitted)}"
            >
              <label for="monitor2" class="form-label asterisk-lbl">Monitor 2</label>
              <input type="text" id="monitor2" class="form-control" formControlName="monitor2" />
              @if(accessoriesForm.get('monitor2').errors?.maxlength){
              <div style="color:red">Monitor name cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4" 
            [ngClass]="{'has-error': accessoriesForm.get('monitor3').invalid && (accessoriesForm.get('monitor3').dirty || accessoriesForm.get('monitor3').touched || submitted)}"
            >
              <label for="monitor3" class="form-label asterisk-lbl">Monitor 3</label>
              <input type="text" id="monitor3" class="form-control" formControlName="monitor3" />
              @if(accessoriesForm.get('monitor3').errors?.maxlength){
              <div style="color:red">Monitor name cannot exceed 50 characters.</div>
              }
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-md-4"  
            >
              <label for="monitor4" class="form-label">Monitor 4</label>
              <input type="text" id="monitor4" class="form-control" formControlName="monitor4" />
              @if(accessoriesForm.get('monitor4').errors?.maxlength){
              <div style="color:red">Monitor name cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4" 
            >
              <label for="monitor5" class="form-label">Monitor 5</label>
              <input type="text" id="monitor5" class="form-control" formControlName="monitor5" />
              @if(accessoriesForm.get('monitor5').errors?.maxlength){
              <div style="color:red">Monitor name cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4"
            >
              <label for="monitor6" class="form-label">Monitor 6</label>
              <input type="text" id="monitor6" class="form-control" formControlName="monitor6" />
              @if(accessoriesForm.get('monitor6').errors?.maxlength){
              <div style="color:red">Monitor name cannot exceed 50 characters.</div>
              }
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="headPhone" class="form-label">Headphone</label>
              <input type="text" id="headphone" class="form-control" formControlName="headPhone" />
              @if(accessoriesForm.get('headPhone').errors?.maxlength){
              <div style="color:red">Cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4">
              <label for="keyboard" class="form-label">Keyboard</label>
              <input type="text" id="keyboard" class="form-control" formControlName="keyboard" />
              @if(accessoriesForm.get('keyboard').errors?.maxlength){
              <div style="color:red">Cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4">
              <label for="assign" class="form-label">Mouse</label>
              <input type="text" id="mouse" class="form-control" formControlName="mouse" />
              @if(accessoriesForm.get('mouse').errors?.maxlength){
              <div style="color:red">Cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4">
              <label for="assign" class="form-label">Camera</label>
              <input type="text" id="mouse" class="form-control" formControlName="camera" />
              @if(accessoriesForm.get('camera').errors?.maxlength){
              <div style="color:red">Cannot exceed 50 characters.</div>
              }
            </div>
            <div class="form-group col-md-4">
              <label for="assign" class="form-label">Speakers</label>
              <input type="text" id="mouse" class="form-control" formControlName="speakers" />
              @if(accessoriesForm.get('speakers').errors?.maxlength){
              <div style="color:red">Cannot exceed 50 characters.</div>
              }
            </div>
            <div class="d-flex justify-content-end align-items-center" style="margin-left: 113px;">
              <!-- <button type="submit" class="btn btn-warning">Submit</button> -->
              @if (!isEdit) {
                <button  type="submit" class="btn btn-warning">Submit</button>
              }
              @if (isEdit) {
                <button  type="submit" class="btn btn-warning">Update</button>
              }
            </div>
          </div>

        </form>
      </div>
      }

      @if(currentStep === 3){
      <div class="qr-creation-container">
        <div class="product-created-cont" style="padding-top: 50px;">
          <svg class="tick-mark" width="76" height="76" viewBox="0 0 76 76" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
              stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61" stroke-width="5.33852"
              stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          @if(!isEdit){
          <h3>Workstation Created!</h3>
          <p>
            Your Workstation has been successfully added.
          </p>
        }
           @if (isEdit) {
          <h3>Workstation Updated!</h3>
          <p>
            Your Workstation has been successfully Updated.
          </p>
        }
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-warning close" data-dismiss="modal" aria-label="Close">OK</button>
        </div>
      </div>
      }
    </div>
  </div>
</div>
</div>

<!-- Preview Image Slider -->
<div class="modal fade" id="imagePreviewModal" tabindex="-1" role="dialog" aria-labelledby="imagePreviewModalTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered img-preview-width" role="document">
    <div class="modal-content img-preview-slider">
      <div class="modal-header ws-slider-header">
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body img-preview-container">
        <div class="d-flex justify-content-between">
          <div class="product-carousel2">

            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
              @if(previewInventory?.images.length > 1){
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                @for ( productImg of previewInventory?.images.slice(1) ; track $index) {
                <li data-target="#carouselExampleIndicators" attr.data-slide-to="{{$index}}"></li>

                }
              </ol>
            }
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    [src]="previewInventory?.images[0] ? previewInventory?.images[0] : '../../../assets/inventories/computer-system.jpg' "
                    class="d-block" alt="...">
                </div>
                @for ( productImg of previewInventory?.images.slice(1) ; track $index) {
                <div class="carousel-item">
                  <img [src]="[productImg]" class="d-block" alt="..." />
                </div>
                }
              </div>
              @if(previewInventory?.images.length > 1){
              <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators"
                data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators"
                data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </button>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Workstation Details -->
@for (workstations of workstation; track $index) {
<div class="modal fade" id="workstationDetailModal" tabindex="-1" role="dialog"
  aria-labelledby="workstationDetailModalTitle" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered workstation-dtl-width" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="workstationDetailModalLongTitle">
          Workstation Details
        </h5>
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body" style="margin-bottom: 40px;">
        <div class="d-flex justify-content-around">
          <div class="workst-image product-carousel">
            <div id="productImagecarousel" class="carousel slide" data-ride="carousel" data-interval="false">
              @if(previewProductImg?.images.length > 1){
              <ol class="carousel-indicators prod-indicators" style="bottom: -26%!important;">
                <li data-target="#productImagecarousel" data-slide-to="0" class="active"></li>
                @for ( productImg of previewProductImg?.images.slice(1) ; track $index) {
                <li data-target="#productImagecarousel" data-slide-to="1" attr.data-slide-to="{{$index}}"></li>
                }
              </ol>
            }
              <div class="carousel-inner">
                <div class="carousel-item product-dtl-img-slider active">
                  <img
                    [src]="previewProductImg?.images[0] ? previewProductImg?.images[0] : '../../../assets/inventories/computer-system.jpg'"
                    class="d-block" alt="...">
                </div>
                @for ( productImg of previewProductImg?.images.slice(1) ; track $index) {
                <div class="carousel-item product-dtl-img-slider">
                  <img [src]="[productImg]" class="d-block" alt="..." />
                </div>

                }
              </div>
              @if(previewProductImg?.images.length > 1){
              <button class="carousel-control-prev product-prev" type="button" data-target="#productImagecarousel"
                data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </button>
              <button class="carousel-control-next product-next" type="button" data-target="#productImagecarousel"
                data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </button>
            }
            </div>
            <!-- <img [src]="workstationDetail?.image ? workstationDetail.image : '../../../assets/inventories/computer-system.jpg' " alt="" style="width:fit-content; height: 250px;" /> -->
          </div>
          <div class="workstation-content">
            <h2>{{workstationDetail?.name}}</h2>
            @if(showWorkstationButton ){
            <div class="workstation-button d-flex align-items-center">
              <button type="edit" class="btn btn-primary" data-toggle="modal" data-target="#addWorkstationModal" data-dismiss="modal" aria-label="Close" (click)="editWorkstation()">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                  <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                    <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path
                      d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
                  </g>
                </svg>
                Edit Workstation
              </button>
              <button type="archive" class="btn delete-workstation d-flex align-items-center justify-content-center" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#deleteProduct" (click)="workstations._id =editWorkstationId">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M7.616 20q-.667 0-1.141-.475T6 18.386V6h-.5q-.213 0-.356-.144T5 5.499t.144-.356T5.5 5H9q0-.31.23-.54t.54-.23h4.46q.31 0 .54.23T15 5h3.5q.213 0 .356.144t.144.357t-.144.356T18.5 6H18v12.385q0 .666-.475 1.14t-1.14.475zm2.692-3q.213 0 .357-.144t.143-.356v-8q0-.213-.144-.356T10.307 8t-.356.144t-.143.356v8q0 .213.144.356q.144.144.356.144m3.385 0q.213 0 .356-.144t.143-.356v-8q0-.213-.144-.356Q13.904 8 13.692 8q-.213 0-.357.144t-.143.356v8q0 .213.144.356t.357.144"/></svg>
                Delete Workstation
              </button>
            </div>
          }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Delete Workstation Modal -->
<div class="modal fade" id="deleteProduct" tabindex="-1" role="dialog" aria-labelledby="deleteProductTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered delete-product-width" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
        <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
            stroke-linecap="round" />
          <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
            stroke-linecap="round" />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="delete-container">
        <svg width="104" height="89" viewBox="0 0 104 89" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M97.9349 88.6895H5.81325C1.64318 88.6895 -0.953878 84.1499 1.17867 80.5605L47.2395 2.89153C49.3298 -0.624 54.4184 -0.624 56.5087 2.89153L102.569 80.5605C104.691 84.1499 102.105 88.6895 97.9349 88.6895Z"
            fill="#FDCC27" />
          <path
            d="M49.0954 60.7142L47.1602 31.8952C46.9876 29.2877 48.9591 27.0254 51.5666 26.8528C54.1741 26.6802 56.4364 28.6517 56.609 31.2592C56.6272 31.4682 56.6181 31.6953 56.609 31.8952L54.6557 60.7142C54.3286 64.1667 49.4225 64.1667 49.0954 60.7142Z"
            fill="#030303" />
          <path
            d="M51.8757 75.0512C54.0083 75.0512 55.737 73.3225 55.737 71.1899C55.737 69.0574 54.0083 67.3286 51.8757 67.3286C49.7432 67.3286 48.0144 69.0574 48.0144 71.1899C48.0144 73.3225 49.7432 75.0512 51.8757 75.0512Z"
            fill="#030303" />
        </svg>
        <h3>Delete Product?</h3>
        <p>Are you sure you want to permanently delete the product?</p>
      </div>
      <div class="delete-button d-flex justify-content-center">
        <button type="delete" class="btn btn-danger" style="margin-right: 20px; width: 215px" (click)="deleteWorkstation(workstations._id)">
          Delete
        </button>
        <button type="archive" class="close btn goback-btn" data-dismiss="modal" aria-label="Close">Go Back</button>
      </div>
    </div>
  </div>
</div>
</div> 
}