import Swal from "sweetalert2";

export function alertModal(type: any, message: string) {
  Swal.fire({
    position: "center",
    icon: type,
    title: message,
    // showConfirmButton: false,
    // timer: 1500,
  });
}

export function alertModalWithoutConfirm(type: any, message: string) {
  Swal.fire({
    position: "center",
    icon: type,
    title: message,
    showConfirmButton: false,
    timer: 1500,
  });
}

export function alertModalWithConfirm(
  type: any,
  message: string,
  rowsIssue: number
) {
  Swal.fire({
    position: "center",
    icon: type,
    title: message,
    html: `<span style="color: red; font-weight:600">But there's an issue in - ${rowsIssue} row no.<br> Please recheck and upload.</span>`,
    showConfirmButton: true,
  });
}

export function alertModalCreate(type: any, message: string) {
  Swal.fire({
    position: "center",
    icon: type,
    title: message,
    // showConfirmButton: false,
    // timer: 1500,
  });
}

export function alertModalDelete(message: string): Promise<boolean> {
  return new Promise((resolve) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Deleted Successfully.",
            "success"
          );
          resolve(true);
        }
        if (!result.isConfirmed) {
          console.log(" confirmation failed " + result.dismiss);
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Deletion cancelled",
            "error"
          );
          // resolve(false);
        }
      });
  });
}

export function alertModalDecline(message: string): Promise<boolean> {
  return new Promise((resolve) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to decline this request?",
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes,Decline it!",
        cancelButtonText: "No,Cancel it!",
        reverseButtons: true,
      })
      .then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Declined!",
            "Request Has been Declined.",
            "success"
          );
          resolve(true);
        }
        if (!result.isConfirmed) {
          console.log(" confirmation failed " + result.dismiss);
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Request Not Declined.",
            "error"
          );
          // resolve(false);
        }
      });
  });
}
export function alertModalWithLogin(message: string) {
  Swal.fire(message);
}
