import { Component } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import {
  FormGroup,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { NoDataComponent } from "../shared/no-data/no-data.component";
import { InventoryService } from 'src/app/service/inventory.service';
import { alertModalWithoutConfirm } from '../../helpers/alert';
import { NgClass } from '@angular/common';

interface Request {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-return-request',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [ MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    NoDataComponent,
    NgClass
  ],
  templateUrl: './return-request.component.html',
  styleUrls: ['./return-request.component.css', '../styles/inventory.css']
})
export class ReturnRequestComponent {

  requests: Request[] = [
     { value: "Return Request", viewValue: "Return Request" },
     { value: "Extend Request", viewValue: "Extended Request" },

  ];

  uploadAssetImageForm: FormGroup;
  declineReasonForm: FormGroup;
  selectedFiles: any[] = [];

  constructor(
    private fb: FormBuilder,
    private inventoryService: InventoryService, ){
      this.uploadAssetImageForm = this.fb.group({
        assetImage: [],
      });

      this.declineReasonForm = this.fb.group({
        rejectionReason: ["", Validators.required],
      });
    }

    userDepartment: string | null = null;
    showUserDashboard = false;
    showAdminRequest = false;
  ngOnInit(){
    this.getAssignedInventoryData();
    this.getExtendData();
    this.userDepartment = localStorage.getItem("department");
    this.showUserDashboard = this.userDepartment === "HR";
    this.showAdminRequest = this.userDepartment === "ADMIN";
  }

  extendInv = [];
  totalExtend: any;
  getExtendData() {
    const field: any = {
      pageNum:1,
      limit: 100,
    };
    this.inventoryService.getExtendRequest(field).subscribe((response) => {
      this.extendInv = response["data"].records;
      this.totalExtend = response["data"].totalCounts;
    });
  }

  // Shimmer Loader
  isShimmerLoading: boolean = true;
  shimmerLoadingValue = ([] = [1, 2, 3, 4 , 5]);

  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.onFilterPendingRequest(value);
  }
  pendingReq: any;
  totalPending: any;
  showReturnHead: boolean = false;
  showServicePending: boolean = false;
  onFilterPendingRequest(
    requestType: string,
    pageNum: number = 1,
    limit: number = 20
  ) {
    const getPendingReqValue: any = {
      requestType: requestType,
    };
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };

    this.showReturnHead = requestType === "Return Request";
    this.showServicePending = requestType === "Extend Request";

    this.inventoryService
      .getPendingRequest(getPendingReqValue, field)
      .subscribe((response) => {
        this.pendingReq = response["data"].records;
        this.totalPending = response["data"].totalCounts;
      });
  }
  inventory: any[] = [];
  totalAssigned: any;
  getAssignedInventoryData() {
    const field: any = {
      pageNum: 1,
      limit: 100,
    };
    this.inventoryService.getAssignInv(field).subscribe(
      (response: any) => {
        this.inventory = response["data"].records.reverse();
        this.totalAssigned = response["data"].totalCounts;
        this.isShimmerLoading = false;
      },
      (error) => {
        console.error("Error", error);
      }
    );
  }
  assignedInventoryId: string;
  selectedInventoryIndex: number | null = null;
  openReturn(assignedInventoryId: string, index: number) {
    this.assignedInventoryId = assignedInventoryId;
    this.selectedInventoryIndex = index;

    this.uploadAssetImageForm.reset();
    this.selectedFiles = [];
     this.showForwardSuccess = false;
    this.uploadAssetImageForm.get("assetImage").enable();
  }

    // ASSET RETURN REQUEST ACCEPT
    acceptReturnRequest(requestId: any) {
      const acceptRequest = {
        action: "Accept",
        requestId: requestId,
        requestType: "Return Request",
      };
      this.inventoryService.adminAction(acceptRequest).subscribe((response) => {
        console.log("called");
        alertModalWithoutConfirm("success", "Request has been Approved!");
        window.location.reload();
        // this.getInventoryReturnRequest();
      });
    }

    previewReturnImg: any;
    openReturnPreviewSlider(index: number) {
      this.previewReturnImg = this.returnInv[index];
    }
    returnInv = [];
    totalReturn: any;
    getInventoryReturnRequest() {
      const field: any = {
        pageNum: 1,
        limit: 20,
      };
      this.inventoryService.getInvReturnRequest(field).subscribe((response) => {
        this.returnInv = response["data"].records;
        this.totalReturn = response["data"].totalCounts;
      });
    }

  //Show Remarks Form on decline click
  showDeclineForm: boolean = false;
  onDeclineButtonClick() {
    this.showDeclineForm = true;
  }
   // ASSET RETURN REQUEST REJECT
   rejectReturnRequest(requestId: any) {
    const rejectValue = {
      ...this.declineReasonForm.value,
    };
    const getRejectValue = {
      rejectionReason: rejectValue.rejectionReason,
      action: "Rejected",
      requestId: requestId,
      requestType: "Return Request",
    };
    this.inventoryService.adminAction(getRejectValue).subscribe((response) => {
      console.log("Decline request", response);
    });
  }

  onFileSelected(event: any) {
    if (event.target.files && event.target.files.length > 0) {
      const files = event.target.files;

      if (files.length + this.selectedFiles.length > 4) {
        alert("You can only upload a maximum of 4 files.");
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.selectedFiles.push({
            file: file,
            name: file.name,
            size: (file.size / 1024).toFixed(2) + " KB",
            preview: e.target.result,
          });
        };
        reader.readAsDataURL(file);
      }

      console.log("Selected Files", this.selectedFiles);

      if (this.selectedFiles.length >= 4) {
        this.uploadAssetImageForm.get("assetImage").disable();
      }
    }
  }

  showForwardSuccess = false;
  onReturnSubmit() {
    console.log(this.uploadAssetImageForm.value);

    if (this.selectedFiles.length === 0) {
      return alertModalWithoutConfirm(
        "warning",
        `Please upload atleast 1 image.`
      );
    }

    const formData = new FormData();
    formData.append("assignedInventoryId", this.assignedInventoryId);

    this.selectedFiles.forEach((fileData: any, index: number) => {
      formData.append(`files[${index}]`, fileData.file, fileData.name);
    });

    this.inventoryService.addAssignedReturn(formData).subscribe(
      (res) => {
        this.showForwardSuccess = true;
        console.log("success");

        if (this.selectedInventoryIndex !== null) {
          this.inventory.splice(this.selectedInventoryIndex, 1);
          this.selectedInventoryIndex = null;
          if (this.selectedInventoryIndex !== null) {
            this.inventory.splice(this.selectedInventoryIndex, 1);
            this.selectedInventoryIndex = null;

            this.totalAssigned--;
          }
        }
      },
      (err) => {
        console.error("failed", err);
      }
    );
  }

  removeFile(file: any) {
    const index = this.selectedFiles.indexOf(file);
    if (index > -1) {
      this.selectedFiles.splice(index, 1);
    }
    if (this.selectedFiles.length < 4) {
      this.uploadAssetImageForm.get("assetImage").enable();
    }
  }

  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if(!this.showServicePending){
      return this.inventory.slice(startIndex, endIndex);
    }

    if(!this.showReturnHead && this.showServicePending){
    return this.returnInv.slice(startIndex, endIndex);
  }
}

  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option = {
      pageNum: page,
      limit: 20,
    };
    if(!this.showServicePending){
      this.inventoryService.getAssignInv(option).subscribe(res => {
        this.inventory = res['data'].records
        this.totalAssigned = res['data'].totalCounts
      }
    );
  }
  if(!this.showReturnHead && this.showServicePending){
    this.inventoryService.getExtendRequest(option).subscribe(res => {
      this.returnInv = res['data'].records
      this.totalReturn = res['data'].totalCounts
    }
  );
}

}

  get totalPages(): number {
    if(!this.showServicePending){
    return Math.ceil(this.totalAssigned / this.itemsPerPage);
  }

}

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index,
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }


}
