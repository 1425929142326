<div class="containerBanner" style="overflow: hidden;">
  <!-- Listing content -->
  <div class="menu-container">
    @if(isAllInventory && !isAssigned && !myAssigned){
    <h1>Inventories <span>({{totalInventory}})</span></h1>
    }
    @if (isAssigned) {
    <h1>Assigned Inventory <span>({{totalAssigned}})</span></h1>
    }
    @if (myAssigned) {
      <h1>Assigned Inventory(Mine) <span>({{totalMyAssigned}})</span></h1>
      }
    <div class="menu-content d-flex align-items-center justify-content-between" style="margin-bottom: 15px;">
      <div class="search-menu">
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"
          class="search-icon">
          <g opacity="0.4">
            <circle cx="11.0795" cy="11.3768" r="7.62168" transform="rotate(-45 11.0795 11.3768)" stroke="#092C4C"
              stroke-width="1.72802" stroke-linecap="round" />
            <path d="M16.6416 16.9393L19.8465 20.1443" stroke="#092C4C" stroke-width="1.72802" stroke-linecap="round" />
          </g>
        </svg>
        <input type="search" placeholder="Search inventory.." [(ngModel)]="searchQuery"
          (keyup.enter)="onSearchInventory()" />
        @if(searchQuery){
        <img class="search-close" style="cursor: pointer" src="../../../assets/closeIcon.png" alt=""
          (click)="clearSearch()" />}
      </div>
      <div class="btn-container d-flex align-items-center justify-content-between styled-select">



        <!-- <select id="all-types" class="form-control" disabled>
          <option value="All Types" selected>All Types</option>
          <option value="Leased">Leased</option>
        </select> -->

        <!-- <select id="rental" class="form-control">
          <option value="select" (click)="getAssignInventory()">Assigned Inventory</option>
        </select> -->

        <mat-form-field>
          <mat-select   placeholder="Inventories">
            @if(showInventoryButton){
            @for (assign of assignedInv; track assign) {
            <mat-option [value]="assign.value" (click)="getAssignedInventory()">{{assign.viewValue}}</mat-option>
            }
          }

            @for (assigned of onlyAssigned; track assigned) {
              <mat-option [value]="assigned.value" (click)="getOnlyAssignedInventory()">{{assigned.viewValue}}</mat-option>
              }
            @for (inv of allInv; track inv) {
            <mat-option [value]="inv.value" (click)="getInventoryData()">{{inv.viewValue}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if(showInventoryButton ){
          <div class="inv-buttons d-flex">
        <button class="d-flex align-items-center justify-content-center add-inv" type="button" data-toggle="modal"
          data-target="#addInventoryModal" (click)="resetClose()" style="margin-left:8px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 32 32"
            style="margin-right: 8px">
            <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
          </svg>
          Inventory
        </button>
        <button class="d-flex align-items-center justify-content-center bulk-upload" disabled type="button"
          style="margin-left: 15px">
          Bulk Upload
        </button>
      </div>
        }
      </div>
    </div>


  </div>


  <!-- Add Inventory Modal -->
  <div class="modal fade" id="addInventoryModal" tabindex="-1" role="dialog" aria-labelledby="addInventoryModalTitle"
    data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered main-width-content" role="document">

      <div class="modal-content" style="height: 620px;">
        <div class="modal-header addInventory-head">
          <h5 class="modal-title" id="addInventoryModalTitle">
            {{isEdit? 'Edit Inventory' : 'Add A New Inventory'}}
          </h5>
          <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
            <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
                stroke-linecap="round" />
              <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
                stroke-linecap="round" />
            </svg>
          </button>
        </div>

        <div class="modal-body addInventory-body">
          <ul class="add-inv-menu">
            <li [ngClass]="{'active': currentStep === 1, 'completed': productForm.valid}" (click)="navigateToStep(1)">
              Product Information
            </li>
            <li [ngClass]="{'active': currentStep === 2, 'completed': batteryForm.valid}" (click)="navigateToStep(2)">
              <svg class="mg-space" width="7" height="8" viewBox="0 0 7 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5"
                  d="M6.58887 3.69341C6.75553 3.78963 6.75553 4.03019 6.58887 4.12642L0.68225 7.5366C0.515584 7.63283 0.307251 7.51255 0.307251 7.3201L0.307251 0.499725C0.307251 0.307275 0.515584 0.186994 0.682251 0.283219L6.58887 3.69341Z"
                  fill="#25252B" />
              </svg>
              Battery & Other Info
            </li>
            <li [ngClass]="{'active': currentStep === 3, 'completed': warrantyForm.valid}"  (keyup)="navigateToStep(3)" tabindex="0" (click)="navigateToStep(3)">
              <svg class="mg-space" width="7" height="8" viewBox="0 0 7 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5"
                  d="M6.58887 3.69341C6.75553 3.78963 6.75553 4.03019 6.58887 4.12642L0.68225 7.5366C0.515584 7.63283 0.307251 7.51255 0.307251 7.3201L0.307251 0.499725C0.307251 0.307275 0.515584 0.186994 0.682251 0.283219L6.58887 3.69341Z"
                  fill="#25252B" />
              </svg>
              Warranty Info
            </li>
            <li [ngClass]="{'active': currentStep === 4, 'completed': currentStep > 3}">
              <svg class="mg-space" width="7" height="8" viewBox="0 0 7 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5"
                  d="M6.58887 3.69341C6.75553 3.78963 6.75553 4.03019 6.58887 4.12642L0.68225 7.5366C0.515584 7.63283 0.307251 7.51255 0.307251 7.3201L0.307251 0.499725C0.307251 0.307275 0.515584 0.186994 0.682251 0.283219L6.58887 3.69341Z"
                  fill="#25252B" />
              </svg>
              QR Creation
            </li>
          </ul>

          <!-- Product Information -->
          @if(currentStep === 1){
          <div class="product-info-container d-flex justify-content-between">
            <div class="prod-col">
              <div class="product-image-upload">
                <div class="upload-content">
                  @if (selectedImages.length > 0) {
                  <ng-container>
                    <img class="previewImg" [src]="previewImagesUrl[0]" alt="">
                  </ng-container>
                  }
                  @if (selectedImages.length === 0) {
                  <ng-container>
                    <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="21.0079" cy="20.397" r="20.2278" fill="#FDCC27" />
                      <path
                        d="M16.584 27.4629C15.7892 27.4043 14.8021 27.5967 13.8483 27.3708C11.9409 26.9191 10.8115 25.6475 10.41 23.7819C10.0168 21.9497 10.6442 20.4355 12.1584 19.3061C12.3676 19.1472 12.4429 19.03 12.3759 18.7707C11.8656 16.5956 13.7563 14.6965 15.9398 15.1985C16.1824 15.257 16.2995 15.2152 16.425 14.9893C17.7134 12.6803 20.1395 11.5342 22.7329 11.986C25.1255 12.4043 27.0497 14.3702 27.4847 16.88C27.5349 17.1561 27.6269 17.2732 27.8947 17.3401C30.2789 17.9257 31.9856 20.4271 31.6844 22.8449C31.3498 25.5303 29.2416 27.4629 26.6565 27.4629C25.3598 27.4629 24.0631 27.4545 22.7664 27.4712C22.4903 27.4712 22.415 27.3876 22.415 27.1199C22.4317 25.8064 22.4234 24.5013 22.415 23.1879C22.415 22.9285 22.4736 22.8114 22.758 22.8282C23.1177 22.8533 23.4858 22.8449 23.8456 22.8282C24.3726 22.8031 24.5734 22.435 24.2722 22.0083C23.3353 20.6949 22.3815 19.3814 21.4362 18.0763C21.1601 17.6999 20.8339 17.6999 20.5578 18.0763C19.6041 19.3814 18.6587 20.6949 17.7217 22.0083C17.4206 22.435 17.613 22.8031 18.1484 22.8282C18.5249 22.8449 18.9013 22.8365 19.2778 22.8282C19.4786 22.8282 19.5873 22.8616 19.579 23.1042C19.5706 24.4679 19.5706 25.8399 19.579 27.2035C19.579 27.4461 19.4702 27.4712 19.2694 27.4712C18.4328 27.4545 17.5963 27.4629 16.584 27.4629Z"
                        fill="white" />
                    </svg>
                  </ng-container>
                  }
                  <h3>Upload Photo</h3>
                </div>
                <input type="file" class="form-control" (change)="onSelectImage($event)" 
                  accept="image/png, image/jpeg" />
              </div>
              <p>
                (Recommended Ratio: 9:9)
              </p>
              <h4>Add Multiple Images</h4>
              <p>Max 4 Images</p>
              <div class="d-flex align-items-center justify-content-center" style="margin-top: 20px;">

                @if (selectedImages.length < 5) { <div class="image-upload-container"
                  [ngStyle]="{ cursor: selectedImages.length === 0 ? 'not-allowed' : 'pointer' }"
                  style="border: 1px solid #e7e7e7;border-radius: 4px;position:relative;margin-right:20px;"
                  [ngStyle]="{ opacity: selectedImages.length === 0 ? 0.7 : 1 }">
                  <svg   xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 32 32"
                    style="font-size:20px;position: absolute;top: 30%;left:30%;cursor: pointer;">
                    <path fill="currentColor" d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z" />
                  </svg>
                  <input type="file" class="form-control" style="opacity: 0;width:60px;height:65px;cursor: pointer;"
                    (change)="onSelectMultipleImages($event)" multiple accept="image/png, image/jpeg"
                    [disabled]="selectedImages.length === 0" />
              </div>
              }
              @for (img of previewImagesUrl.slice(1); track img; let i =$index; ) {
              <ng-container>
                <div class=" multiple-img">
                  <img [src]="img" alt="" style="width: 50px;height: 50px;" />
                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"
                    (click)="removeMultipleImage(i + 1)">
                    <circle cx="13.4069" cy="13.9219" r="13" fill="#353535" />
                    <path d="M10.2818 10.7974L16.5319 17.0475" stroke="white" stroke-width="1.5"
                      stroke-linecap="round" />
                    <path d="M10.2818 17.0474L16.5319 10.7972" stroke="white" stroke-width="1.5"
                      stroke-linecap="round" />
                  </svg>
                </div>
              </ng-container>
              }
            </div>
          </div>

          <div style="width: 60%;">
            <form [formGroup]="productForm" (ngSubmit)="onSubmitProduct()">
              <div class="form-row">
                <div class="form-group col-md-6"
                  [ngClass]="{'has-error': productForm.get('assetName').invalid && (productForm.get('assetName').dirty || productForm.get('assetName').touched || submitted)}">
                  <label for="assetName" class="form-label asterisk-lbl">Asset Name</label>
                  <input type="text" id="assetName" class="form-control" formControlName="assetName" />
                  @if(productForm.get('assetName').errors?.maxlength){
                  <div style="color:red">Asset Name cannot exceed 50 characters.</div>
                  }
                </div>

                <div class="form-group col-md-6"
                  [ngClass]="{'has-error': productForm.get('productId').invalid && (productForm.get('productId').dirty || productForm.get('productId').touched || submitted)}">
                  <label for="productId" class="form-label asterisk-lbl">Product ID</label>
                  <input type="text" id="productId" class="form-control" formControlName="productId" />
                  @if(productForm.get('productId').errors?.maxlength){
                  <div style="color:red">Product ID cannot exceed 25 characters.</div>
                  }
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6"
                  [ngClass]="{'has-error': productForm.get('location').invalid && (productForm.get('location').dirty || productForm.get('location').touched || submitted)}">
                  <label for="location" class="form-label asterisk-lbl">Location</label>
                  <input type="text" id="location" class="form-control" formControlName="location" />
                  @if(productForm.get('location').errors?.maxlength){
                  <div style="color:red">location cannot exceed 50 characters.</div>
                  }
                </div>

                <div class="form-group col-md-6"
                  [ngClass]="{'has-error': productForm.get('type').invalid && (productForm.get('type').dirty || productForm.get('type').touched || submitted)}">
                  <label class="labelish asterisk-lbl">Type</label>
                  <div class="rd-button d-flex" (change)="onTypeChange($event)">
                    <div class="d-flex justify-content-between align-items-center">
                      <input id="owned"   type="radio" value="Owned" formControlName="type" />
                      <label for="owned">Owned</label>
                    </div>

                    <div class="d-flex justify-content-between align-items-center" style="margin-left: 90px;">
                      <input id="rental"  type="radio" value="Rental" formControlName="type" />
                      <label for="rental">Rental</label>
                    </div>

                  </div>
                  @if(productForm.get('type').invalid && (productForm.get('type').dirty ||
                  productForm.get('type').touched || submitted)){
                  <div class="validation-message">
                    Please select a type.
                  </div>
                  }
                </div>
                @if(showRentedDate){
                <div class="form-group col-md-6"
                  [ngClass]="{'has-error': productForm.get('rentedDate').invalid && (productForm.get('rentedDate').dirty || productForm.get('rentedDate').touched || submitted)}">
                  <label for="rentedDate" class="asterisk-lbl">Rented Till</label>
                  <input type="date" value="" class="form-control" id="rentedDate" formControlName="rentedDate">
                </div>
                }
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="accessoriesInv" class="form-label">Accessories</label>
                  <input type="text" id="accessoriesInv" class="form-control" formControlName="accessoriesInv" />
                </div>
                <div class="form-group col-md-6 productcost-input">
                  <label for="productCost" class="form-label asterisk-lbl">Product Cost (₹)</label>
                  <input type="text" id="productCost" class="form-control" formControlName="productCost"
                    [value]="formatCurrency(productForm.get('productCost').value)"
                    (input)="onProductCostInput($event)" />
                </div>

              </div>
              <div class="d-flex justify-content-end align-items-center" style="margin-top:30px;">
                <button type="submit" class="btn btn-warning">Next</button>
              </div>
            </form>
          </div>

        </div>

        }
        <!-- Battery & Storage -->
        @if(currentStep === 2){
        <div class="battery-storage-container">
          <form [formGroup]="batteryForm" (ngSubmit)="onSubmitBattery()">
            <div class="form-row">

              <div class="form-group col-md-4" [ngClass]="{'has-error': batteryForm.get('batteryType').invalid && (batteryForm.get('batteryType').dirty ||
                batteryForm.get('batteryType').touched || submitted)}">
                <label class="labelish asterisk-lbl">Battery Type</label>
                <div class="rd-button d-flex" (change)="onBatteryTypeChange($event)">
                  <div class="d-flex justify-content-between align-items-center">
                    <input id="yes1"   type="radio" value="Chargeable" formControlName="batteryType" />
                    <label for="yes1">Chargeable</label>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mg-left">
                    <input id="no1"  type="radio" value="Not Chargeable"
                      formControlName="batteryType" />
                    <label for="no1">Non Chargeable</label>
                  </div>
                </div>
                @if(batteryForm.get('batteryType').invalid && (batteryForm.get('batteryType').dirty ||
                batteryForm.get('batteryType').touched || submitted)){
                <div class="validation-message">
                  Please select a battery type.
                </div>
                }
              </div>

              <div class="form-group col-md-4" [ngClass]="{'has-error': batteryForm.get('storageValue').invalid && (batteryForm.get('storageValue').dirty ||
                batteryForm.get('storageValue').touched || submitted)}">
                <label for="storage" class="form-label asterisk-lbl">Storage Volume</label>
                <input type="text" id="storage" class="form-control" formControlName="storageValue"
                  [disabled]="!isStorageEnabled" />
                  @if(batteryForm.get('storageValue').errors?.maxlength){
                    <div style="color:red">storage volume cannot exceed 50 characters.</div>
                    }
              </div>

              <div class="form-group col-md-4">
                <p class="labelish asterisk-lbl">Does product have storage?</p>
                <div class="rd-button d-flex" (change)="onStorageTypeChange($event)">
                  <div class="d-flex justify-content-between align-items-center">
                    <input id="yes2"  type="radio" value="yes" formControlName="storageType" />
                    <label for="yes2">Yes</label>
                  </div>

                  <div class="d-flex justify-content-between align-items-center mg-left">
                    <input id="no2"  type="radio" value="No" formControlName="storageType" />
                    <label for="no2">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              @if(showRechargeCycle){
              <div class="form-group col-md-4" formGroupName="recharge">

                <label for="rechargeCy" class="form-label asterisk-lbl">Recharge Cycle (In Days)</label>
                <div class="d-flex">
                  <div class="col-md-4 styled-select" style="padding-left: 0;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style="position: absolute;
                      left:335px;
                      top: 10px;
                      font-size: 17px;">
                      <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.5" d="m7 10l5 5l5-5" />
                    </svg>
                    <select id="rechargeCy" class="form-control" formControlName="number" style="width: 367px;">
                      @for(number of numbers; track $index){
                      <option [value]="number" style="max-height: 280px;overflow: scroll;">{{ number }}</option>
                      }
                    </select>
                  </div>
                </div>
              </div>
              }
              <div class="form-group col-md-4 d-block styled-select" [ngClass]="{'has-error': batteryForm.get('assetCategory').invalid && (batteryForm.get('assetCategory').dirty ||
                batteryForm.get('assetCategory').touched || submitted)}">
                <label for="assetCategory" class="form-label scrollable-dropdown d-flex  asterisk-lbl1">Asset
                  Category</label>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style="position: absolute;
                  right:22px;
                  top: 36px;
                  font-size: 17px;">
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1.5" d="m7 10l5 5l5-5" />
                  </svg> -->
                <mat-form-field>
                  <mat-select formControlName="assetCategory">
                    @for (cat of categories; track cat) {
                    <mat-option [value]="cat.value">{{cat.viewValue}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

              </div>
              <div class="form-group col-md-4" [ngClass]="{'has-error': batteryForm.get('brand').invalid && (batteryForm.get('brand').dirty ||
                batteryForm.get('brand').touched || submitted)}">
                <label for="brand" class="form-label asterisk-lbl">Brand</label>
                <input type="text" id="brand" class="form-control" formControlName="brand" />
                @if(batteryForm.get('brand').errors?.maxlength){
                  <div style="color:red">Brand name cannot exceed 100 characters.</div>
                  }
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4" [ngClass]="{'has-error': batteryForm.get('quantity').invalid && (batteryForm.get('quantity').dirty ||
                batteryForm.get('quantity').touched || submitted)}">
                <label for="quantity" class="form-label asterisk-lbl">Qty</label>
                <input type="number" id="quantity" class="form-control"  min="1" max="100"
                  formControlName="quantity">
              </div>
              <div class="form-group col-md-4" [ngClass]="{'has-error': batteryForm.get('size').invalid && (batteryForm.get('size').dirty ||
                batteryForm.get('size').touched || submitted)}">
                <label for="size" class="form-label asterisk-lbl">Size</label>
                <input type="text" id="size" class="form-control" formControlName="size" />
                @if(batteryForm.get('size').errors?.maxlength){
                  <div style="color:red">size cannot exceed 50 characters.</div>
                  }
              </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <button type="submit" class="btn btn-warning" style="margin-top:30px">
                Next
              </button>
            </div>
          </form>
        </div>
        }
        <!-- Warraty Info -->
        @if(currentStep === 3){
        <div class="warranty-info-container ">
          <form [formGroup]="warrantyForm" (ngSubmit)="onSubmitWarranty()">
            <div class="warranty-content d-flex justify-content-between">
              <div>
                <p class="labelish">Does product cover Warranty</p>
                <div class="rd-button d-flex" (change)="onWarrantyTypeChange($event)">
                  <div class=" d-flex justify-content-between align-items-center">
                    <input id="yes3"  type="radio" value="yes" formControlName="warranty" />
                    <label for="yes3">Yes</label>
                  </div>

                  <div class="d-flex justify-content-between align-items-center mg-left">
                    <input id="no3"   type="radio" value="No" formControlName="warranty" />
                    <label for="no3">No</label>
                  </div>
                </div>
              </div>

              <div>
                <div class="form-group col-md-12">
                  <label for="expiryDate">Warranty expiry date</label>
                  <input type="date" id="expiryDate" class="form-control" style="width:367px"
                    formControlName="expiryDate" />                      
                </div>
                <div class="form-group col-md-12">
                  <label for="contactPerson" class="form-label">Contact person</label>
                  <input type="text" id="contactPerson" class="form-control" style="width: 366px"
                    formControlName="contactPerson" />
                </div>
                <div class="form-group col-md-12 phone-num">
                  <label for="contactPerson" class="form-label">Phone number</label>
                  <input type="number" id="phoneNumber" class="form-control" style="width: 366px"
                    formControlName="phoneNumber" />
                </div>
              </div>

              <div>
                <label class="labelish" for="invoice">Upload Invoice</label>
                <div class="invoice-upload">
                  <div class="invoice-content">
                    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.73291" y="0.833008" width="44" height="44" rx="22" fill="#F5F5F5" />
                      <path
                        d="M19.7329 28.583C19.3229 28.583 18.9829 28.243 18.9829 27.833V23.643L18.2629 24.363C17.9729 24.653 17.4929 24.653 17.2029 24.363C16.9129 24.073 16.9129 23.593 17.2029 23.303L19.2029 21.303C19.4129 21.093 19.7429 21.023 20.0229 21.143C20.3029 21.253 20.4829 21.533 20.4829 21.833V27.833C20.4829 28.243 20.1429 28.583 19.7329 28.583Z"
                        fill="#030303" />
                      <path
                        d="M21.7329 24.5829C21.5429 24.5829 21.3529 24.5129 21.2029 24.3629L19.2029 22.3629C18.9129 22.0729 18.9129 21.5929 19.2029 21.3029C19.4929 21.0129 19.9729 21.0129 20.2629 21.3029L22.2629 23.3029C22.5529 23.5929 22.5529 24.0729 22.2629 24.3629C22.1129 24.5129 21.9229 24.5829 21.7329 24.5829Z"
                        fill="#030303" />
                      <path
                        d="M25.7329 33.583H19.7329C14.3029 33.583 11.9829 31.263 11.9829 25.833V19.833C11.9829 14.403 14.3029 12.083 19.7329 12.083H24.7329C25.1429 12.083 25.4829 12.423 25.4829 12.833C25.4829 13.243 25.1429 13.583 24.7329 13.583H19.7329C15.1229 13.583 13.4829 15.223 13.4829 19.833V25.833C13.4829 30.443 15.1229 32.083 19.7329 32.083H25.7329C30.3429 32.083 31.9829 30.443 31.9829 25.833V20.833C31.9829 20.423 32.3229 20.083 32.7329 20.083C33.1429 20.083 33.4829 20.423 33.4829 20.833V25.833C33.4829 31.263 31.1629 33.583 25.7329 33.583Z"
                        fill="#030303" />
                      <path
                        d="M32.7329 21.583H28.7329C25.3129 21.583 23.9829 20.253 23.9829 16.833V12.833C23.9829 12.533 24.1629 12.253 24.4429 12.143C24.7229 12.023 25.0429 12.093 25.2629 12.303L33.2629 20.303C33.4729 20.513 33.5429 20.843 33.4229 21.123C33.3029 21.403 33.0329 21.583 32.7329 21.583ZM25.4829 14.643V16.833C25.4829 19.413 26.1529 20.083 28.7329 20.083H30.9229L25.4829 14.643Z"
                        fill="#030303" />
                    </svg>

                    <p class="drag-text">
                      <span>Click to Upload</span>
                    </p>
                    <p class="file-size">(Max. File size:25 MB)</p>
                  </div>
                  <input type="file" class="form-control" formControlName="invoice"
                    accept=".png,.pdf,.doc,.docx,.xls,.xlsx" (change)="onFileChange($event)"  [ngStyle]="{ 'cursor': isWarrantyEnabled ? 'pointer' : 'not-allowed' }" />
                  @if(imagePreviewUrl){
                  <div class="preview-container" style="height: 100%;">
                    <img [src]="imagePreviewUrl" alt="Image preview" class="preview-img" />
                  </div>
                  }
                  <!-- PDF Preview -->
                  @if(pdfPreviewUrl){
                  <div class="preview-container">

                    <!-- <div id="my-pdf"></div> -->

                    <iframe [src]="pdfPreviewUrl" type="application/pdf" class="preview-pdf"
                      style="height: 100%;"></iframe>
                  </div>
                  }
                  @if(filePreviewUrl){
                  <!-- <div class="preview-container">
                    <iframe [src]="filePreviewUrl" type="application/xlsx" class="preview-pdf"
                      style="height: 100%;"></iframe>
                  </div> -->
                  }
                  @if(!imagePreviewUrl && !pdfPreviewUrl && filePreviewUrl){
                  <div>
                  <img src="../../../../assets/excl-img.jpg" style="position: absolute;
                  top: 30px;
                  width: 200px;
                  height: 200px;
                  left: 17%;" />
                  </div>
                }
                </div>
              </div>
              <div style="visibility: hidden;"></div>
            </div>
           
            <div class="d-flex justify-content-end align-items-center" style="margin-top: 30px; bottom: 58px; position: absolute; right: 2%;">
            @if (!isEdit) {
              <button  type="submit" class="btn btn-warning">Submit</button>
            }
            @if (isEdit) {
              <button  type="submit" class="btn btn-warning">Update</button>
            }
            </div>
          </form>

        </div>
        }

        <!-- QR Information -->
        @if(currentStep === 4){
        <div class="qr-creation-container">
          <div class="product-created-cont">
            <svg class="tick-mark" width="76" height="76" viewBox="0 0 76 76" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M37.7808 3.91014C46.0698 3.91014 54.0763 6.92354 60.3083 12.3889C66.5404 17.8543 70.573 25.3989 71.655 33.617C72.7369 41.8352 70.7943 50.1664 66.1892 57.0586C61.584 63.9507 54.6303 68.9336 46.6237 71.079C38.6171 73.2244 30.1035 72.3858 22.6693 68.7197C15.2351 65.0535 9.38711 58.8097 6.21502 51.1516C3.04292 43.4935 2.76302 34.9434 5.42745 27.0942C8.09189 19.245 13.5189 12.6321 20.6975 8.48759"
                stroke="#24BA61" stroke-width="6.40622" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M24.9683 38.0768L33.5099 46.6184L50.5932 29.5352" stroke="#24BA61" stroke-width="5.33852"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <h3>{{isEdit? 'Product Updated!' : 'Product Created!'}}</h3>
            <p>
              Your inventory has been successfully added. You can now download or <br> print the corresponding
              barcode.
            </p>
            <div class="qr-button d-flex justify-content-center align-items-center" style="margin: 40px 0">
              <button type="download" class="btn btn-warning" style="margin-right: 15px ; cursor:pointer"
                (click)="downloadQRCode()">
                Download
              </button>
              <button type="" class="btn btn-warning" (click)="printQRCode()">Print</button>
            </div>
          </div>
        </div>
        }
      </div>
    </div>

  </div>
</div>

<!-- Available Inventories -->
@if (!isAssigned && !myAssigned) {
<div class="inv-listing">
@if(!isShimmerLoading){
  <table style="overflow-x: scroll;overflow-y: scroll;max-height: 518px;height:518px;">
    <thead>
      <tr>
        <th scope="col" style="padding-left: 22px;width: 35%;">ASSET NAME</th>
        @if (showInventoryButton) {
        <th scope="col">ASSIGN TO</th>
        }
        <th scope="col" style="width: 20%;" >Qty</th>
        <th scope="col" style="width: 20%;">PRODUCT ID</th>
        <th scope="col" style="width: 20%;">LOCATION</th>
        <th scope="col" style="width: 20%;">TYPE</th>
        <th scope="col" style="width: 20%;">COST</th>
        <th scope="col" style="width: 20%;">ADDED BY</th>
        <!-- <th scope="col">CONDITION</th> -->
        <th scope="col" style="width: 20%;">ACCESSORIES</th>
        <th scope="col" style="width: 20%;">STORAGE</th>
        <th scope="col" style="width: 20%;">BATTERY</th>
        <th scope="col" style="width: 20%;">CHARGE CYCLE</th>
        <th scope="col" style="width: 20%;">NEXT CYCLE</th>
        <th scope="col" style="width: 20%;">WARRANTY</th>
        <th scope="col" style="width: 20%;">CONTACT INFO</th>
        <th scope="col" style="width: 20%;">INVOICE</th>
      </tr>
    </thead>
    <tbody>
      @for (inventories of inventory; track $index) {
      <tr>
        <td (click)="getSingleInventory(inventories.assetId)">
          <div class="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" style="display: none;margin-right: 10px;
              font-size: 20px;
              cursor: pointer;">
              <path fill="currentColor"
                d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
            </svg>
            <div class="inventory-img" data-target="#imagePreviewModal" data-toggle="modal"
              (click)="openPreviewSlider($index)">
              <img
                [src]="inventories.images[0]? inventories.images[0] : '../../../assets/inventories/computer-system.jpg' "
                alt="" style="width: 60px;height:60px;" />
            </div>
            <div class="d-flex align-items-center" style="cursor: pointer;" data-toggle="modal"
              data-target="#productDetailModal" (click)="openProductDetail($index)">
              <p>{{inventories.name}}</p>
              <!-- <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.4" cx="15.5637" cy="15.6975" r="14.5" stroke="#030303" />
                  <path d="M20.6784 13.8025L15.5637 18.9172L10.4491 13.8025" stroke="#030303" stroke-width="1.75"
                    stroke-linejoin="round" />
                </svg> -->
            </div>

          </div>
        </td>

       
        @if (showInventoryButton) { 
        <td>
          <div>
            <mat-form-field>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="margin-right:7px;margin-left: -8px;">
                <g clip-path="url(#clip0_3007_4385)">
                  <path
                    d="M8.50048 8.02773C9.19742 8.02867 9.87897 7.82285 10.4589 7.43633C11.0389 7.04981 11.4911 6.49996 11.7585 5.85634C12.0258 5.21272 12.0963 4.50426 11.9609 3.82059C11.8255 3.13693 11.4903 2.50879 10.9978 2.01565C10.5054 1.52251 9.87768 1.18653 9.1942 1.05021C8.51072 0.913904 7.80216 0.983391 7.15818 1.24988C6.51421 1.51637 5.96374 1.96789 5.57645 2.54731C5.18915 3.12673 4.98242 3.80801 4.98242 4.50495C4.98242 5.43844 5.35292 6.33375 6.01255 6.99427C6.67218 7.65478 7.567 8.02648 8.50048 8.02773ZM8.50048 1.9219C9.01082 1.92096 9.50997 2.07151 9.9347 2.35446C10.3594 2.63741 10.6906 3.04004 10.8864 3.51136C11.0821 3.98268 11.1336 4.50149 11.0342 5.00208C10.9349 5.50267 10.6892 5.96251 10.3284 6.32338C9.96748 6.68425 9.50763 6.92991 9.00705 7.02925C8.50646 7.12858 7.98765 7.07713 7.51633 6.88139C7.04501 6.68566 6.64238 6.35446 6.35943 5.92973C6.07647 5.505 5.92593 5.00586 5.92687 4.49551C5.92811 3.81333 6.19966 3.15945 6.68204 2.67707C7.16441 2.1947 7.8183 1.92315 8.50048 1.9219Z"
                    fill="#8496A6" />
                  <path
                    d="M2.83341 15.0591V12.1691C3.56246 11.404 4.44277 10.7989 5.41835 10.3924C6.39392 9.98591 7.44342 9.78689 8.50008 9.808C9.94635 9.78827 11.3699 10.1691 12.6131 10.9083L13.2506 10.1952C11.8291 9.29989 10.18 8.83267 8.50008 8.84939C7.27098 8.81808 6.05011 9.05831 4.92451 9.55295C3.7989 10.0476 2.79629 10.7845 1.98814 11.7111C1.92514 11.7922 1.89033 11.8917 1.88897 11.9944V15.0591C1.88262 15.3163 1.97843 15.5656 2.15544 15.7523C2.33244 15.9391 2.57622 16.0481 2.83341 16.0555H8.68425L7.78702 15.1111L2.83341 15.0591Z"
                    fill="#8496A6" />
                  <path
                    d="M14.1666 15.0591V15.1111H12.6791L11.8291 16.0555H14.1666C14.4205 16.0481 14.6614 15.9417 14.8379 15.7591C15.0143 15.5764 15.1124 15.332 15.111 15.078V12.3722L14.1666 13.4252V15.0591Z"
                    fill="#8496A6" />
                  <path
                    d="M16.4142 8.79272C16.3209 8.7096 16.1983 8.66689 16.0735 8.67398C15.9487 8.68106 15.8318 8.73735 15.7484 8.8305L10.2612 14.9694L7.80563 12.3297C7.76533 12.2825 7.71613 12.2438 7.66085 12.2157C7.60558 12.1876 7.5453 12.1706 7.48347 12.1658C7.42164 12.161 7.35947 12.1684 7.3005 12.1876C7.24153 12.2068 7.18693 12.2374 7.1398 12.2777C7.09363 12.3197 7.05625 12.3704 7.02981 12.427C7.00338 12.4835 6.98841 12.5447 6.98577 12.6071C6.98314 12.6694 6.99289 12.7317 7.01446 12.7903C7.03604 12.8488 7.06901 12.9025 7.11147 12.9483L10.2706 16.3483L16.452 9.44438C16.5308 9.35179 16.5709 9.23236 16.5639 9.11097C16.5568 8.98958 16.5032 8.87558 16.4142 8.79272Z"
                    fill="#8496A6" />
                </g>
                <defs>
                  <clipPath id="clip0_3007_4385">
                    <rect width="17" height="17" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <mat-select>

                @for (user of users; track user) {
                <mat-option (click)="assignInventory(user, inventories)"
                  [value]="user.value ? user.value : 'Assign TO'" >
                  <svg style="margin-right:10px" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.5 10.625L9.375 12.5L12.5 8.125M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                      stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  {{user.viewValue}}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </td>
        }
        <td style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index) ; getSingleInventory(inventories.assetId)">
          
            <p class="text-center">{{inventories?.quantity}}</p>
         
        </td>
        <td style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.productId}}</p>
        </td>
        <td style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.location}}</p>
        </td>
        <td class="inv-type" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.type}}</p>
        </td>
        <td class="inv-cost" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{formatCurrency(inventories?.cost)}}</p>
          <p>{{inventories?.rentedTill}}</p>
        </td>
        <td class="inv-added" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.addedOn}}</p>
          <p>{{inventories?.addedBy}}</p>
        </td>
        <!-- <td class="inv-condition">
          <p>{{inventories?.condition}}</p>
        </td> -->
        <td class="inv-accessories" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          @for(accessory of inventories.accessories; track $index) {
          <p>{{ accessory?.name ? accessory.name : '-' }}</p>
          }
        </td>
        <td class="inv-storage" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.storage ? inventories.storage : '-'}}</p>
        </td>
        <td class="inv-battery" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.battery}}</p>
        </td>
        <td class="inv-charge-cycle" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.chargeCycle ? inventories?.chargeCycle : '-' }}</p>
        </td>
        <td class="inv-next-cycle" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.nextCycle ? inventories?.nextCycle : '-' }}</p>
        </td>
        <td class="inv-warranty" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>{{inventories?.warranty?.remainingTime || "-"}} <span>({{inventories?.warranty?.remainingDays ||
              "0"}}Days)</span>
          </p>
        </td>
        <td class="inv-contact" style="cursor: pointer;" data-toggle="modal"
        data-target="#productDetailModal" (click)="openProductDetail($index); getSingleInventory(inventories.assetId)">
          <p>
            <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.58203 16.5515C5.45531 16.5515 3.32231 16.5515 1.19559 16.5515C0.648183 16.5515 0.503466 16.4005 0.522342 15.8594C0.648183 12.9147 2.68052 10.4734 5.58745 10.0015C7.18563 9.74352 8.83415 9.6869 10.4135 10.1903C12.9051 10.9894 14.6165 13.3363 14.6606 15.9538C14.6669 16.3879 14.5033 16.5515 14.0503 16.5515C11.8984 16.5515 9.7402 16.5515 7.58203 16.5515ZM7.59461 15.6077C9.53257 15.6077 11.4705 15.6077 13.4085 15.6077C13.5909 15.6077 13.7231 15.6203 13.6853 15.3497C13.3896 13.179 11.7411 11.3983 9.59549 10.939C8.67055 10.744 7.73933 10.8321 6.80811 10.8321C4.08365 10.8195 1.97581 12.6127 1.51649 15.2931C1.47244 15.5322 1.51649 15.614 1.78075 15.614C3.71871 15.6014 5.65666 15.6077 7.59461 15.6077Z"
                fill="#8496A6" />
              <path
                d="M11.8418 4.70359C11.8355 7.05681 9.91646 8.95702 7.56323 8.94443C5.23517 8.93185 3.32867 7.00019 3.34755 4.67213C3.37272 2.3189 5.27292 0.437571 7.61356 0.443863C9.94792 0.450155 11.8481 2.36294 11.8418 4.70359ZM10.8917 4.67842C10.8791 2.85372 9.40051 1.38767 7.57581 1.39396C5.7637 1.39396 4.28507 2.89147 4.29765 4.70988C4.31024 6.53457 5.78887 8.00062 7.61356 7.99433C9.43197 7.98804 10.9043 6.49682 10.8917 4.67842Z"
                fill="#8496A6" />
            </svg>
            {{inventories?.warranty?.contactName ? inventories?.warranty?.contactName : "-" }}
          </p>
          <p>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.14838 0.320964C3.64977 0.320964 4.15116 0.314279 4.65255 0.320964C5.49489 0.334335 6.05645 0.882521 6.08319 1.71817C6.10993 2.71427 6.24363 3.69031 6.54447 4.6396C6.72497 5.20116 6.62469 5.69587 6.23695 6.16383C5.78904 6.70533 5.39461 7.28026 4.96007 7.83513C4.83974 7.98889 4.86648 8.09585 4.9467 8.24961C5.98291 10.1816 7.46702 11.6524 9.39236 12.6886C9.55949 12.7755 9.65977 12.7621 9.80684 12.6552C10.3885 12.2073 10.9834 11.7727 11.5584 11.3181C11.9528 11.0106 12.3806 10.917 12.8419 11.0574C13.8581 11.3649 14.8943 11.532 15.9639 11.5588C16.7394 11.5788 17.2943 12.1605 17.3076 12.9426C17.321 13.9788 17.321 15.015 17.3076 16.0512C17.2943 16.8735 16.6993 17.4484 15.8636 17.4351C11.5049 17.3682 7.76786 15.7972 4.70604 12.702C2.29936 10.2685 0.868731 7.32705 0.360656 3.94434C0.253693 3.21566 0.193526 2.48028 0.200211 1.74491C0.206896 0.915947 0.781824 0.34102 1.61079 0.32765C2.12555 0.314279 2.63362 0.320964 3.14838 0.320964ZM16.365 14.507C16.365 14.0256 16.365 13.551 16.365 13.0696C16.3583 12.6618 16.2113 12.5014 15.7968 12.5014C14.7405 12.4947 13.711 12.3276 12.7082 12.0067C12.4542 11.9265 12.267 11.98 12.0664 12.1337C11.4046 12.6485 10.7294 13.1365 10.0743 13.6513C9.80684 13.8585 9.57286 13.8585 9.27871 13.7114C6.91884 12.5081 5.13389 10.7365 3.93055 8.37663C3.76342 8.04906 3.78348 7.8017 4.00409 7.52093C4.51217 6.87246 4.9935 6.20394 5.50158 5.55548C5.66871 5.34155 5.70213 5.13431 5.62191 4.87359C5.31439 3.90423 5.15394 2.90814 5.14057 1.8853C5.13389 1.3906 5.00019 1.26358 4.49211 1.26358C3.58961 1.26358 2.68711 1.26358 1.7846 1.26358C1.26984 1.26358 1.13614 1.40397 1.14282 1.91873C1.18962 4.02457 1.59742 6.06355 2.54672 7.94878C5.30102 13.3838 9.73331 16.2317 15.8168 16.4858C16.1979 16.4992 16.365 16.332 16.3717 15.9443C16.3717 15.4629 16.365 14.9883 16.365 14.507Z"
                fill="#092C4C" />
            </svg>

            {{inventories?.warranty?.contactNo ? inventories?.warranty?.contactNo : '-' }}
          </p>
        </td>

        <td>
          <button type="button" class="inv-download" [disabled]="!inventories?.warranty?.invoice"
            [ngClass]="{ 'disabled-opacity': !inventories?.warranty?.invoice }"
            (click)="downloadInvoice(inventories?.warranty?.invoice)"> Download </button>
        </td>
      </tr>

      }
      <tr>
        <div class="history-container align-items-center justify-content-between d-none">
          <h4>Approval History<span>(6)</span></h4>

          <div class="history-record d-flex align-items-center">
            <div class="prev-hist d-flex align-items-center">
              <div>
                <p>01 Jan 2024</p>
                <p>Anoop Kumar</p>
              </div>
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.13501" y="0.203796" width="40" height="40" rx="8" fill="#FDCC27" />
                <path
                  d="M20.1324 28.5418C17.2481 28.5418 14.3637 28.5353 11.4794 28.545C10.9308 28.5482 10.438 28.4453 10.0307 28.0661C9.67257 27.7287 9.47875 27.3141 9.47546 26.8224C9.47546 24.3286 9.47546 21.8315 9.47546 19.3377C9.47546 17.448 9.47875 15.5584 9.47546 13.6655C9.47546 12.6339 10.2442 11.8626 11.2987 11.8626C15.9833 11.8626 20.6712 11.8626 25.3558 11.8626C26.5647 11.8626 27.7769 11.8626 28.9859 11.8626C29.8663 11.8626 30.5791 12.4282 30.7598 13.2638C30.8058 13.4791 30.7927 13.7008 30.7927 13.9194C30.7927 16.9885 30.7927 20.0576 30.7927 23.1234C30.7927 24.3929 30.7992 25.6623 30.7763 26.9317C30.7631 27.6805 30.1619 28.3393 29.4096 28.5C29.3144 28.5193 29.2191 28.5385 29.1173 28.5385C26.1212 28.5418 23.1285 28.5418 20.1324 28.5418ZM20.1193 27.1534C23.0463 27.1534 25.9767 27.1534 28.9037 27.1534C29.2487 27.1534 29.3636 27.041 29.3636 26.7003C29.3636 22.3618 29.3636 18.0265 29.3636 13.688C29.3636 13.3827 29.2421 13.2541 28.9333 13.2541C27.4944 13.2541 26.0555 13.2541 24.6133 13.2541C22.4353 13.2541 20.2573 13.2541 18.0792 13.2541C15.8289 13.2541 13.5786 13.2541 11.3316 13.2541C11.0228 13.2541 10.9012 13.3827 10.9012 13.688C10.9012 14.3371 10.9012 14.9831 10.9012 15.6323C10.9012 19.3313 10.9012 23.0335 10.9012 26.7324C10.9012 27.0152 11.0359 27.147 11.3184 27.147C14.252 27.1534 17.1857 27.1534 20.1193 27.1534Z"
                  fill="black" />
                <path
                  d="M20.1226 25.7683C17.5864 25.7683 15.0536 25.7651 12.5175 25.7715C12.3466 25.7715 12.3105 25.7265 12.3138 25.5658C12.3237 24.9777 12.3204 24.3864 12.3171 23.7983C12.3171 23.7115 12.3434 23.6505 12.4058 23.5894C13.526 22.4967 14.6462 21.4041 15.7599 20.305C15.8552 20.2118 15.9012 20.2279 15.9866 20.3114C16.5056 20.8256 17.0312 21.3334 17.5503 21.8476C17.6259 21.9215 17.6686 21.9215 17.7441 21.8476C19.0417 20.5749 20.3427 19.3023 21.6436 18.0297C22.1725 17.5123 22.7047 16.9949 23.2336 16.4742C23.2993 16.41 23.3387 16.381 23.4241 16.4646C24.9057 17.9204 26.3939 19.373 27.882 20.8256C27.9379 20.8802 27.9543 20.9349 27.9543 21.0088C27.9543 22.5449 27.951 24.0843 27.9576 25.6205C27.9576 25.7715 27.882 25.7651 27.7769 25.7651C25.2244 25.7683 22.6718 25.7683 20.1226 25.7683Z"
                  fill="black" />
                <path
                  d="M14.4492 18.8106C13.2764 18.8106 12.3171 17.8787 12.3171 16.7474C12.3204 15.5712 13.2665 14.6457 14.459 14.6489C15.6417 14.6521 16.6009 15.5873 16.5812 16.7506C16.5615 17.9494 15.576 18.8203 14.4492 18.8106Z"
                  fill="black" />
              </svg>
            </div>
            <div style="
                  height: 30px;
                  border-left: 1px solid #5a5a5a;
                  margin: 0 25px;
                "></div>
            <div class="next-hist d-flex align-items-center">
              <div>
                <p>05 June 2024</p>
                <p>Tanvi Singh</p>
              </div>
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.13501" y="0.203796" width="40" height="40" rx="8" fill="#FDCC27" />
                <path
                  d="M20.1324 28.5418C17.2481 28.5418 14.3637 28.5353 11.4794 28.545C10.9308 28.5482 10.438 28.4453 10.0307 28.0661C9.67257 27.7287 9.47875 27.3141 9.47546 26.8224C9.47546 24.3286 9.47546 21.8315 9.47546 19.3377C9.47546 17.448 9.47875 15.5584 9.47546 13.6655C9.47546 12.6339 10.2442 11.8626 11.2987 11.8626C15.9833 11.8626 20.6712 11.8626 25.3558 11.8626C26.5647 11.8626 27.7769 11.8626 28.9859 11.8626C29.8663 11.8626 30.5791 12.4282 30.7598 13.2638C30.8058 13.4791 30.7927 13.7008 30.7927 13.9194C30.7927 16.9885 30.7927 20.0576 30.7927 23.1234C30.7927 24.3929 30.7992 25.6623 30.7763 26.9317C30.7631 27.6805 30.1619 28.3393 29.4096 28.5C29.3144 28.5193 29.2191 28.5385 29.1173 28.5385C26.1212 28.5418 23.1285 28.5418 20.1324 28.5418ZM20.1193 27.1534C23.0463 27.1534 25.9767 27.1534 28.9037 27.1534C29.2487 27.1534 29.3636 27.041 29.3636 26.7003C29.3636 22.3618 29.3636 18.0265 29.3636 13.688C29.3636 13.3827 29.2421 13.2541 28.9333 13.2541C27.4944 13.2541 26.0555 13.2541 24.6133 13.2541C22.4353 13.2541 20.2573 13.2541 18.0792 13.2541C15.8289 13.2541 13.5786 13.2541 11.3316 13.2541C11.0228 13.2541 10.9012 13.3827 10.9012 13.688C10.9012 14.3371 10.9012 14.9831 10.9012 15.6323C10.9012 19.3313 10.9012 23.0335 10.9012 26.7324C10.9012 27.0152 11.0359 27.147 11.3184 27.147C14.252 27.1534 17.1857 27.1534 20.1193 27.1534Z"
                  fill="black" />
                <path
                  d="M20.1226 25.7683C17.5864 25.7683 15.0536 25.7651 12.5175 25.7715C12.3466 25.7715 12.3105 25.7265 12.3138 25.5658C12.3237 24.9777 12.3204 24.3864 12.3171 23.7983C12.3171 23.7115 12.3434 23.6505 12.4058 23.5894C13.526 22.4967 14.6462 21.4041 15.7599 20.305C15.8552 20.2118 15.9012 20.2279 15.9866 20.3114C16.5056 20.8256 17.0312 21.3334 17.5503 21.8476C17.6259 21.9215 17.6686 21.9215 17.7441 21.8476C19.0417 20.5749 20.3427 19.3023 21.6436 18.0297C22.1725 17.5123 22.7047 16.9949 23.2336 16.4742C23.2993 16.41 23.3387 16.381 23.4241 16.4646C24.9057 17.9204 26.3939 19.373 27.882 20.8256C27.9379 20.8802 27.9543 20.9349 27.9543 21.0088C27.9543 22.5449 27.951 24.0843 27.9576 25.6205C27.9576 25.7715 27.882 25.7651 27.7769 25.7651C25.2244 25.7683 22.6718 25.7683 20.1226 25.7683Z"
                  fill="black" />
                <path
                  d="M14.4492 18.8106C13.2764 18.8106 12.3171 17.8787 12.3171 16.7474C12.3204 15.5712 13.2665 14.6457 14.459 14.6489C15.6417 14.6521 16.6009 15.5873 16.5812 16.7506C16.5615 17.9494 15.576 18.8203 14.4492 18.8106Z"
                  fill="black" />
              </svg>
            </div>
          </div>

          <div style="width: 50px; border-bottom: 1px solid #e7e7e7"></div>

          <div class="history-record d-flex align-items-center">
            <div class="prev-hist d-flex align-items-center">
              <div>
                <p>01 Jan 2024</p>
                <p>Anoop Kumar</p>
              </div>
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.13501" y="0.203796" width="40" height="40" rx="8" fill="#FDCC27" />
                <path
                  d="M20.1324 28.5418C17.2481 28.5418 14.3637 28.5353 11.4794 28.545C10.9308 28.5482 10.438 28.4453 10.0307 28.0661C9.67257 27.7287 9.47875 27.3141 9.47546 26.8224C9.47546 24.3286 9.47546 21.8315 9.47546 19.3377C9.47546 17.448 9.47875 15.5584 9.47546 13.6655C9.47546 12.6339 10.2442 11.8626 11.2987 11.8626C15.9833 11.8626 20.6712 11.8626 25.3558 11.8626C26.5647 11.8626 27.7769 11.8626 28.9859 11.8626C29.8663 11.8626 30.5791 12.4282 30.7598 13.2638C30.8058 13.4791 30.7927 13.7008 30.7927 13.9194C30.7927 16.9885 30.7927 20.0576 30.7927 23.1234C30.7927 24.3929 30.7992 25.6623 30.7763 26.9317C30.7631 27.6805 30.1619 28.3393 29.4096 28.5C29.3144 28.5193 29.2191 28.5385 29.1173 28.5385C26.1212 28.5418 23.1285 28.5418 20.1324 28.5418ZM20.1193 27.1534C23.0463 27.1534 25.9767 27.1534 28.9037 27.1534C29.2487 27.1534 29.3636 27.041 29.3636 26.7003C29.3636 22.3618 29.3636 18.0265 29.3636 13.688C29.3636 13.3827 29.2421 13.2541 28.9333 13.2541C27.4944 13.2541 26.0555 13.2541 24.6133 13.2541C22.4353 13.2541 20.2573 13.2541 18.0792 13.2541C15.8289 13.2541 13.5786 13.2541 11.3316 13.2541C11.0228 13.2541 10.9012 13.3827 10.9012 13.688C10.9012 14.3371 10.9012 14.9831 10.9012 15.6323C10.9012 19.3313 10.9012 23.0335 10.9012 26.7324C10.9012 27.0152 11.0359 27.147 11.3184 27.147C14.252 27.1534 17.1857 27.1534 20.1193 27.1534Z"
                  fill="black" />
                <path
                  d="M20.1226 25.7683C17.5864 25.7683 15.0536 25.7651 12.5175 25.7715C12.3466 25.7715 12.3105 25.7265 12.3138 25.5658C12.3237 24.9777 12.3204 24.3864 12.3171 23.7983C12.3171 23.7115 12.3434 23.6505 12.4058 23.5894C13.526 22.4967 14.6462 21.4041 15.7599 20.305C15.8552 20.2118 15.9012 20.2279 15.9866 20.3114C16.5056 20.8256 17.0312 21.3334 17.5503 21.8476C17.6259 21.9215 17.6686 21.9215 17.7441 21.8476C19.0417 20.5749 20.3427 19.3023 21.6436 18.0297C22.1725 17.5123 22.7047 16.9949 23.2336 16.4742C23.2993 16.41 23.3387 16.381 23.4241 16.4646C24.9057 17.9204 26.3939 19.373 27.882 20.8256C27.9379 20.8802 27.9543 20.9349 27.9543 21.0088C27.9543 22.5449 27.951 24.0843 27.9576 25.6205C27.9576 25.7715 27.882 25.7651 27.7769 25.7651C25.2244 25.7683 22.6718 25.7683 20.1226 25.7683Z"
                  fill="black" />
                <path
                  d="M14.4492 18.8106C13.2764 18.8106 12.3171 17.8787 12.3171 16.7474C12.3204 15.5712 13.2665 14.6457 14.459 14.6489C15.6417 14.6521 16.6009 15.5873 16.5812 16.7506C16.5615 17.9494 15.576 18.8203 14.4492 18.8106Z"
                  fill="black" />
              </svg>
            </div>
            <div style="
                  height: 30px;
                  border-left: 1px solid #5a5a5a;
                  margin: 0 25px;
                "></div>
            <div class="next-hist d-flex align-items-center">
              <div>
                <p>05 June 2024</p>
                <p>Tanvi Singh</p>
              </div>
              <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.13501" y="0.203796" width="40" height="40" rx="8" fill="#FDCC27" />
                <path
                  d="M20.1324 28.5418C17.2481 28.5418 14.3637 28.5353 11.4794 28.545C10.9308 28.5482 10.438 28.4453 10.0307 28.0661C9.67257 27.7287 9.47875 27.3141 9.47546 26.8224C9.47546 24.3286 9.47546 21.8315 9.47546 19.3377C9.47546 17.448 9.47875 15.5584 9.47546 13.6655C9.47546 12.6339 10.2442 11.8626 11.2987 11.8626C15.9833 11.8626 20.6712 11.8626 25.3558 11.8626C26.5647 11.8626 27.7769 11.8626 28.9859 11.8626C29.8663 11.8626 30.5791 12.4282 30.7598 13.2638C30.8058 13.4791 30.7927 13.7008 30.7927 13.9194C30.7927 16.9885 30.7927 20.0576 30.7927 23.1234C30.7927 24.3929 30.7992 25.6623 30.7763 26.9317C30.7631 27.6805 30.1619 28.3393 29.4096 28.5C29.3144 28.5193 29.2191 28.5385 29.1173 28.5385C26.1212 28.5418 23.1285 28.5418 20.1324 28.5418ZM20.1193 27.1534C23.0463 27.1534 25.9767 27.1534 28.9037 27.1534C29.2487 27.1534 29.3636 27.041 29.3636 26.7003C29.3636 22.3618 29.3636 18.0265 29.3636 13.688C29.3636 13.3827 29.2421 13.2541 28.9333 13.2541C27.4944 13.2541 26.0555 13.2541 24.6133 13.2541C22.4353 13.2541 20.2573 13.2541 18.0792 13.2541C15.8289 13.2541 13.5786 13.2541 11.3316 13.2541C11.0228 13.2541 10.9012 13.3827 10.9012 13.688C10.9012 14.3371 10.9012 14.9831 10.9012 15.6323C10.9012 19.3313 10.9012 23.0335 10.9012 26.7324C10.9012 27.0152 11.0359 27.147 11.3184 27.147C14.252 27.1534 17.1857 27.1534 20.1193 27.1534Z"
                  fill="black" />
                <path
                  d="M20.1226 25.7683C17.5864 25.7683 15.0536 25.7651 12.5175 25.7715C12.3466 25.7715 12.3105 25.7265 12.3138 25.5658C12.3237 24.9777 12.3204 24.3864 12.3171 23.7983C12.3171 23.7115 12.3434 23.6505 12.4058 23.5894C13.526 22.4967 14.6462 21.4041 15.7599 20.305C15.8552 20.2118 15.9012 20.2279 15.9866 20.3114C16.5056 20.8256 17.0312 21.3334 17.5503 21.8476C17.6259 21.9215 17.6686 21.9215 17.7441 21.8476C19.0417 20.5749 20.3427 19.3023 21.6436 18.0297C22.1725 17.5123 22.7047 16.9949 23.2336 16.4742C23.2993 16.41 23.3387 16.381 23.4241 16.4646C24.9057 17.9204 26.3939 19.373 27.882 20.8256C27.9379 20.8802 27.9543 20.9349 27.9543 21.0088C27.9543 22.5449 27.951 24.0843 27.9576 25.6205C27.9576 25.7715 27.882 25.7651 27.7769 25.7651C25.2244 25.7683 22.6718 25.7683 20.1226 25.7683Z"
                  fill="black" />
                <path
                  d="M14.4492 18.8106C13.2764 18.8106 12.3171 17.8787 12.3171 16.7474C12.3204 15.5712 13.2665 14.6457 14.459 14.6489C15.6417 14.6521 16.6009 15.5873 16.5812 16.7506C16.5615 17.9494 15.576 18.8203 14.4492 18.8106Z"
                  fill="black" />
              </svg>
            </div>
          </div>
        </div>
      </tr>
    </tbody>
  </table>
}
@if(isShimmerLoading) {  
  @for (inventories of shimmerLoadingValue; track inventories; let
  i = $index) { 
  <span class="d-flex">
    <div class="shimmer-card">
      <div class="shimmerBG media"></div>
      <div class="p-32">
        <div class="shimmerBG title-line"></div>
        <div class="shimmerBG title-line end"></div>

        <div class="shimmerBG content-line m-t-24"></div>
        <div class="shimmerBG content-line"></div>
        <div class="shimmerBG content-line"></div>
        <div class="shimmerBG content-line"></div>
        <div class="shimmerBG content-line end"></div>
      </div>
    </div>
  </span>
  } }


</div>
}
<!-- Assigned Assets Table -->
@if (isAssigned) {
<div class="checkout-request">
  <div class="checked-out-inv" style="overflow-x: auto; max-height: 518px;height: 518px;">
    <table>
      <thead>
        <tr style="border-top: 1px solid #e7e7e7; border-bottom: 1px solid #e7e7e7;">
          <th scope="col" style="width: 25%">ASSET NAME</th>
          <th scope="col" style="width:30%">ASSIGNED ON</th>
          <th scope="col" style="width: 25%">ASSIGNED TO</th>
          <th scope="col" style="width:30%">PRODUCT ID</th>
          <th scope="col" style="width:30%">TYPE</th>
          <th scope="col" style="width:30%">COST</th>
          <th scope="col" style="width:30%">ACCESSORIES</th>
          <th scope="col" style="width:25%">LOCATION</th>
          <!-- <th scope="col" style="width:30%">STORAGE</th> -->
          <th scope="col" style="width:30%">BATTERY</th>
          <!-- <th scope="col" style="width:30%">CHARGE CYCLE</th>
          <th scope="col" style="width:30%">NEXT CYCLE</th> -->
          <!-- <th scope="col" style="width:30%">WARRANTY</th> -->
          <th scope="col" style="width:30%">CONTACT INFO</th>
          <th scope="col" style="width:30%">INVOICE</th>
          <th scope="col" style="width:30%">REQUESTED FOR</th>
          <!-- <th scope="col" style="width:30%">FOR PROJECT</th> -->
        </tr>
      </thead>
      <tbody>
        @for (assign of assigned ; track $index) {
        <tr>
          <td>
            <div class="d-flex align-items-center" style="width: 80%">
              <div class="inventory-img">
                <img
                  [src]="assign.assetImage ? assign.assetImage : '../../../../assets/inventories/computer-system.png '"
                  alt="" width="60px" height="60px" />
              </div>
              <p data-toggle="modal">{{assign.assetName}}</p>
            </div>
          </td>
          <td>
            <p>{{assign.assignedOn}}</p>
          </td>
          <td>
            <p>{{assign.userName}}</p>
          </td>
          <td>
            <p>{{assign.assetData?.productId}}</p>
          </td>
          <td>
            <p>{{assign.assetData?.type}}</p>
          </td>
          <td>
            <p>{{formatCurrency(assign.assetData?.cost)}}</p>
          </td>
          <td class="inv-accessories">
            @for(accessory of assign.assetData.accessories; track $index) {
            <p>{{ accessory.name ? accessory.name : "-" }}</p>
            }
          </td>
          <td>
            <p>{{assign.assetData?.location}}</p>
          </td>
          <td>
            <p>{{assign.assetData?.batteryType}}</p>
          </td>
          <td class="inv-contact">
            <p>
              <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.58203 16.5515C5.45531 16.5515 3.32231 16.5515 1.19559 16.5515C0.648183 16.5515 0.503466 16.4005 0.522342 15.8594C0.648183 12.9147 2.68052 10.4734 5.58745 10.0015C7.18563 9.74352 8.83415 9.6869 10.4135 10.1903C12.9051 10.9894 14.6165 13.3363 14.6606 15.9538C14.6669 16.3879 14.5033 16.5515 14.0503 16.5515C11.8984 16.5515 9.7402 16.5515 7.58203 16.5515ZM7.59461 15.6077C9.53257 15.6077 11.4705 15.6077 13.4085 15.6077C13.5909 15.6077 13.7231 15.6203 13.6853 15.3497C13.3896 13.179 11.7411 11.3983 9.59549 10.939C8.67055 10.744 7.73933 10.8321 6.80811 10.8321C4.08365 10.8195 1.97581 12.6127 1.51649 15.2931C1.47244 15.5322 1.51649 15.614 1.78075 15.614C3.71871 15.6014 5.65666 15.6077 7.59461 15.6077Z"
                  fill="#8496A6" />
                <path
                  d="M11.8418 4.70359C11.8355 7.05681 9.91646 8.95702 7.56323 8.94443C5.23517 8.93185 3.32867 7.00019 3.34755 4.67213C3.37272 2.3189 5.27292 0.437571 7.61356 0.443863C9.94792 0.450155 11.8481 2.36294 11.8418 4.70359ZM10.8917 4.67842C10.8791 2.85372 9.40051 1.38767 7.57581 1.39396C5.7637 1.39396 4.28507 2.89147 4.29765 4.70988C4.31024 6.53457 5.78887 8.00062 7.61356 7.99433C9.43197 7.98804 10.9043 6.49682 10.8917 4.67842Z"
                  fill="#8496A6" />
              </svg>
              {{assign.assetData?.warranty?.contactPerson ? assign.assetData?.warranty?.contactPerson : "-" }}
            </p>
            <p>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.14838 0.320964C3.64977 0.320964 4.15116 0.314279 4.65255 0.320964C5.49489 0.334335 6.05645 0.882521 6.08319 1.71817C6.10993 2.71427 6.24363 3.69031 6.54447 4.6396C6.72497 5.20116 6.62469 5.69587 6.23695 6.16383C5.78904 6.70533 5.39461 7.28026 4.96007 7.83513C4.83974 7.98889 4.86648 8.09585 4.9467 8.24961C5.98291 10.1816 7.46702 11.6524 9.39236 12.6886C9.55949 12.7755 9.65977 12.7621 9.80684 12.6552C10.3885 12.2073 10.9834 11.7727 11.5584 11.3181C11.9528 11.0106 12.3806 10.917 12.8419 11.0574C13.8581 11.3649 14.8943 11.532 15.9639 11.5588C16.7394 11.5788 17.2943 12.1605 17.3076 12.9426C17.321 13.9788 17.321 15.015 17.3076 16.0512C17.2943 16.8735 16.6993 17.4484 15.8636 17.4351C11.5049 17.3682 7.76786 15.7972 4.70604 12.702C2.29936 10.2685 0.868731 7.32705 0.360656 3.94434C0.253693 3.21566 0.193526 2.48028 0.200211 1.74491C0.206896 0.915947 0.781824 0.34102 1.61079 0.32765C2.12555 0.314279 2.63362 0.320964 3.14838 0.320964ZM16.365 14.507C16.365 14.0256 16.365 13.551 16.365 13.0696C16.3583 12.6618 16.2113 12.5014 15.7968 12.5014C14.7405 12.4947 13.711 12.3276 12.7082 12.0067C12.4542 11.9265 12.267 11.98 12.0664 12.1337C11.4046 12.6485 10.7294 13.1365 10.0743 13.6513C9.80684 13.8585 9.57286 13.8585 9.27871 13.7114C6.91884 12.5081 5.13389 10.7365 3.93055 8.37663C3.76342 8.04906 3.78348 7.8017 4.00409 7.52093C4.51217 6.87246 4.9935 6.20394 5.50158 5.55548C5.66871 5.34155 5.70213 5.13431 5.62191 4.87359C5.31439 3.90423 5.15394 2.90814 5.14057 1.8853C5.13389 1.3906 5.00019 1.26358 4.49211 1.26358C3.58961 1.26358 2.68711 1.26358 1.7846 1.26358C1.26984 1.26358 1.13614 1.40397 1.14282 1.91873C1.18962 4.02457 1.59742 6.06355 2.54672 7.94878C5.30102 13.3838 9.73331 16.2317 15.8168 16.4858C16.1979 16.4992 16.365 16.332 16.3717 15.9443C16.3717 15.4629 16.365 14.9883 16.365 14.507Z"
                  fill="#092C4C" />
              </svg>
  
              {{assign.assetData?.warranty?.phoneNumber ? assign.assetData?.warranty?.phoneNumber : '-' }}
            </p>
          </td>
          <td>
            <button type="button" class="inv-download" [disabled]="!assign?.assetData.warranty?.invoice"
              [ngClass]="{ 'disabled-opacity': !assign?.assetData.warranty?.invoice }"
              (click)="downloadInvoice(assign?.assetData.warranty?.invoice)"> Download </button>
          </td>
          <td>
            <p class="">{{assign.requestedFor ? assign.requestedFor : '-' }}</p>
          </td>
          <!-- <td class="project-name">
            <p>{{assign.project ? assign.project : '-'}}</p>
          </td> -->

          <!-- <td>
            <div class="d-none align-items-center justify-content-between">
              <button class="send d-none">Send Reminder</button>
              <button class="sent">
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 5px">
                  <path d="M1.76489 4.95386L5.23359 8.5006L12.0059 1.57594" stroke="#24BA61" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Reminder Sent
              </button>
              <button class="take-action">Extend</button>
            </div>
            <div class="d-flex align-items-center justify-content-between">

              <button class="decline" data-toggle="modal" data-target="#assignedReturnModal"
                > Return</button>
            </div>
          </td> -->
        </tr>
        }
      </tbody>
    </table>
  </div>
</div>
}

<!-- Assigned only Assets Table -->
@if (myAssigned) {
  <div class="checkout-request">
    <div class="checked-out-inv" style="overflow-x: auto; max-height: 518px; height:518px;">
      <table>
        <thead>
          <tr style="border-top: 1px solid #e7e7e7; border-bottom: 1px solid #e7e7e7;">
            <th scope="col" style="width: 25%">ASSET NAME</th>
            <th scope="col" style="width: 25%">ASSIGNED ON</th>
            <th scope="col" style="width:30%">PRODUCT ID</th>
            <th scope="col" style="width:30%">TYPE</th>
            <th scope="col" style="width:30%">COST</th>
            <th scope="col" style="width:30%">ACCESSORIES</th>
            <th scope="col" style="width:25%">LOCATION</th>
            <!-- <th scope="col" style="width:30%">STORAGE</th> -->
            <th scope="col" style="width:30%">BATTERY</th>
            <!-- <th scope="col" style="width:30%">CHARGE CYCLE</th>
            <th scope="col" style="width:30%">NEXT CYCLE</th> -->
            <!-- <th scope="col" style="width:30%">WARRANTY</th> -->
            <th scope="col" style="width:30%">CONTACT INFO</th>
            <th scope="col" style="width:30%">INVOICE</th>
            <th scope="col" style="width:30%">REQUESTED FOR</th>
            <!-- <th scope="col" style="width:30%">FOR PROJECT</th> -->
          </tr>
        </thead>
        <tbody>
          @for (myassign of ownAssigned ; track $index) {
          <tr>
            <td>
              <div class="d-flex align-items-center" style="width: 80%">
                <div class="inventory-img">
                  <img
                    [src]="myassign.assetImage ? myassign.assetImage : '../../../../assets/inventories/computer-system.png '"
                    alt="" width="60px" height="60px" />
                </div>
                <p data-toggle="modal">{{myassign.assetName}}</p>
              </div>
            </td>
            <td>
              <p>{{myassign.assignedOn}}</p>
            </td>
            <td>
              <p>{{myassign.assetData?.productId}}</p>
            </td>
            <td>
              <p>{{myassign.assetData?.type}}</p>
            </td>
            <td>
              <p>{{formatCurrency(myassign.assetData?.cost)}}</p>
            </td>
            <td class="inv-accessories">
              @for(accessory of myassign.assetData.accessories; track $index) {
              <p>{{ accessory?.name ? accessory?.name : "-" }}</p>
              }
            </td>
            <td>
              <p>{{myassign.assetData?.location}}</p>
            </td>
            <td>
              <p>{{myassign.assetData?.batteryType}}</p>
            </td>
            <td class="inv-contact">
              <p>
                <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.58203 16.5515C5.45531 16.5515 3.32231 16.5515 1.19559 16.5515C0.648183 16.5515 0.503466 16.4005 0.522342 15.8594C0.648183 12.9147 2.68052 10.4734 5.58745 10.0015C7.18563 9.74352 8.83415 9.6869 10.4135 10.1903C12.9051 10.9894 14.6165 13.3363 14.6606 15.9538C14.6669 16.3879 14.5033 16.5515 14.0503 16.5515C11.8984 16.5515 9.7402 16.5515 7.58203 16.5515ZM7.59461 15.6077C9.53257 15.6077 11.4705 15.6077 13.4085 15.6077C13.5909 15.6077 13.7231 15.6203 13.6853 15.3497C13.3896 13.179 11.7411 11.3983 9.59549 10.939C8.67055 10.744 7.73933 10.8321 6.80811 10.8321C4.08365 10.8195 1.97581 12.6127 1.51649 15.2931C1.47244 15.5322 1.51649 15.614 1.78075 15.614C3.71871 15.6014 5.65666 15.6077 7.59461 15.6077Z"
                    fill="#8496A6" />
                  <path
                    d="M11.8418 4.70359C11.8355 7.05681 9.91646 8.95702 7.56323 8.94443C5.23517 8.93185 3.32867 7.00019 3.34755 4.67213C3.37272 2.3189 5.27292 0.437571 7.61356 0.443863C9.94792 0.450155 11.8481 2.36294 11.8418 4.70359ZM10.8917 4.67842C10.8791 2.85372 9.40051 1.38767 7.57581 1.39396C5.7637 1.39396 4.28507 2.89147 4.29765 4.70988C4.31024 6.53457 5.78887 8.00062 7.61356 7.99433C9.43197 7.98804 10.9043 6.49682 10.8917 4.67842Z"
                    fill="#8496A6" />
                </svg>
                {{myassign.assetData?.warranty?.contactPerson ? myassign.assetData?.warranty?.contactPerson : "-" }}
              </p>
              <p>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.14838 0.320964C3.64977 0.320964 4.15116 0.314279 4.65255 0.320964C5.49489 0.334335 6.05645 0.882521 6.08319 1.71817C6.10993 2.71427 6.24363 3.69031 6.54447 4.6396C6.72497 5.20116 6.62469 5.69587 6.23695 6.16383C5.78904 6.70533 5.39461 7.28026 4.96007 7.83513C4.83974 7.98889 4.86648 8.09585 4.9467 8.24961C5.98291 10.1816 7.46702 11.6524 9.39236 12.6886C9.55949 12.7755 9.65977 12.7621 9.80684 12.6552C10.3885 12.2073 10.9834 11.7727 11.5584 11.3181C11.9528 11.0106 12.3806 10.917 12.8419 11.0574C13.8581 11.3649 14.8943 11.532 15.9639 11.5588C16.7394 11.5788 17.2943 12.1605 17.3076 12.9426C17.321 13.9788 17.321 15.015 17.3076 16.0512C17.2943 16.8735 16.6993 17.4484 15.8636 17.4351C11.5049 17.3682 7.76786 15.7972 4.70604 12.702C2.29936 10.2685 0.868731 7.32705 0.360656 3.94434C0.253693 3.21566 0.193526 2.48028 0.200211 1.74491C0.206896 0.915947 0.781824 0.34102 1.61079 0.32765C2.12555 0.314279 2.63362 0.320964 3.14838 0.320964ZM16.365 14.507C16.365 14.0256 16.365 13.551 16.365 13.0696C16.3583 12.6618 16.2113 12.5014 15.7968 12.5014C14.7405 12.4947 13.711 12.3276 12.7082 12.0067C12.4542 11.9265 12.267 11.98 12.0664 12.1337C11.4046 12.6485 10.7294 13.1365 10.0743 13.6513C9.80684 13.8585 9.57286 13.8585 9.27871 13.7114C6.91884 12.5081 5.13389 10.7365 3.93055 8.37663C3.76342 8.04906 3.78348 7.8017 4.00409 7.52093C4.51217 6.87246 4.9935 6.20394 5.50158 5.55548C5.66871 5.34155 5.70213 5.13431 5.62191 4.87359C5.31439 3.90423 5.15394 2.90814 5.14057 1.8853C5.13389 1.3906 5.00019 1.26358 4.49211 1.26358C3.58961 1.26358 2.68711 1.26358 1.7846 1.26358C1.26984 1.26358 1.13614 1.40397 1.14282 1.91873C1.18962 4.02457 1.59742 6.06355 2.54672 7.94878C5.30102 13.3838 9.73331 16.2317 15.8168 16.4858C16.1979 16.4992 16.365 16.332 16.3717 15.9443C16.3717 15.4629 16.365 14.9883 16.365 14.507Z"
                    fill="#092C4C" />
                </svg>
    
                {{myassign.assetData?.warranty?.phoneNumber ? myassign.assetData?.warranty?.phoneNumber : '-' }}
              </p>
            </td>
            <td>
              <button type="button" class="inv-download" [disabled]="!myassign?.assetData.warranty?.invoice"
                [ngClass]="{ 'disabled-opacity': !myassign?.assetData.warranty?.invoice }"
                (click)="downloadInvoice(myassign?.assetData.warranty?.invoice)"> Download </button>
            </td>
            <td>
              <p class="">{{myassign.requestedFor ? myassign.requestedFor : '-' }}</p>
            </td>
            <!-- <td class="project-name">
              <p>{{myassign.project ? myassign.project : '-'}}</p>
            </td> -->
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
  }



  <!-- Pagination -->
  <nav aria-label="Page navigation example" style="position: absolute;left: 50%;bottom:2%;">
    <ul class="pagination" style="margin-top: 10px;">
      <li class="page-item" [ngClass]="{ disabled: currentPage === 1 }">
        <a class="page-link" href="javascript:void(0)" aria-label="Previous"
          (click)="currentPage !== 1 ? pageChanged(currentPage - 1) : null">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
            <path fill="currentColor" d="m4 10l9 9l1.4-1.5L7 10l7.4-7.5L13 1z" />
          </svg>
        </a>
      </li>
      @for(page of visiblePages; track page){
      <li class="page-item" [ngClass]="{
          active: currentPage === page,
          hidden: !isPageVisible(page)
        }">
        <a class="page-link" href="javascript:void(0)" (click)="pageChanged(page)">{{ page }}</a>
      </li>
      }
  
      <li class="page-item" [ngClass]="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="javascript:void(0)" aria-label="Next" (click)="
            currentPage !== totalPages ? pageChanged(currentPage + 1) : null
          ">
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
            <path fill="currentColor" d="M7 1L5.6 2.5L13 10l-7.4 7.5L7 19l9-9z" />
          </svg>
        </a>
      </li>
    </ul>
  </nav> 


<!-- Inventory Image Slider -->
<div class="modal fade" id="imagePreviewModal" tabindex="-1" role="dialog" aria-labelledby="imagePreviewModalTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered img-preview-width" role="document">
    <div class="modal-content img-preview-slider">
      <div class="modal-header inv-slider-header">
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body img-preview-container">
        <div class="d-flex justify-content-between">
          <div class="product-carousel2">

            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
             @if(previewInventory?.images.length > 1){
              <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                @for ( productImg of previewInventory?.images.slice(1) ; track $index) {
                <li data-target="#carouselExampleIndicators" attr.data-slide-to="{{$index}}"></li>

                }
              </ol>
            }
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    [src]="previewInventory?.images[0] ? previewInventory?.images[0] : '../../../assets/inventories/computer-system.jpg' "
                    class="d-block" alt="...">
                </div>
                @for ( productImg of previewInventory?.images.slice(1) ; track $index) {
                <div class="carousel-item">
                  <img [src]="[productImg]" class="d-block" alt="..." />
                </div>

                }

              </div>
              @if(previewInventory?.images.length > 1){
              <button class="carousel-control-prev" type="button" data-target="#carouselExampleIndicators"
                data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-target="#carouselExampleIndicators"
                data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </button>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Product Details -->
@for (inventories of inventory; track $index) {
<div  class="modal fade" id="productDetailModal" tabindex="-1" role="dialog" aria-labelledby="productDetailModalTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered product-dtl-width" role="document">
    <div class="modal-content product-detail-content">
      <div class="modal-header">
        <h5 class="modal-title" id="productDetailModalLongTitle">
          Product Details
        </h5>
        <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
          <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
            <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313" stroke-linecap="round" />
          </svg>
        </button>
      </div>
      <div class="modal-body pro-dtl-container">
        <div class="prod-content d-flex justify-content-around">
          <div class="product-carousel">
            <div id="productImagecarousel" class="carousel slide" data-ride="carousel" data-interval="false">
              @if(previewInventory?.images.length > 1){
              <ol class="carousel-indicators prod-indicators">
                <li data-target="#productImagecarousel" data-slide-to="0" class="active"></li>
                @for ( productImg of previewProductImg?.images.slice(1) ; track $index) {
                <li data-target="#productImagecarousel" data-slide-to="1" attr.data-slide-to="{{$index}}"></li>
                }
              </ol>
            }
              <div class="carousel-inner">
                <div class="carousel-item product-dtl-img-slider active">
                  <img
                    [src]="previewProductImg?.images[0] ? previewProductImg?.images[0] : '../../../assets/inventories/computer-system.jpg'"
                    class="d-block" alt="...">
                </div>
                @for ( productImg of previewProductImg?.images.slice(1) ; track $index) {
                <div class="carousel-item product-dtl-img-slider">
                  <img [src]="[productImg]" class="d-block" alt="..." />
                </div>

                }
              </div>
              @if(previewInventory?.images.length > 1){
              <button class="carousel-control-prev product-prev" type="button" data-target="#productImagecarousel"
                data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </button>
              <button class="carousel-control-next product-next" type="button" data-target="#productImagecarousel"
                data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </button>
            }
            </div>
          </div>
          

          <div class="product-qr-content">
            <h2 style="padding-top: 20px;">{{productDetail?.name}}</h2>
            <img [src]="[productDetail?.qrCode]" alt="qrCode" width="220px"  style="margin-left: -26px;">
            <button (click)="download(productDetail?.qrCode)"
            style="border: none;
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            display: block;
            background: #24ba61;
            padding: 10px 22px;
            border-radius: 8px;
        ">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 11h8V3H3zm2-6h4v4H5zM3 21h8v-8H3zm2-6h4v4H5zm8-12v8h8V3zm6 6h-4V5h4zm-5.99 4h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm4 0h2v2h-2zm2 2h2v2h-2zm-4 0h2v2h-2zm2-6h2v2h-2zm2 2h2v2h-2z"/></svg>
            Download
            QR</button>
            @if(showInventoryButton){
            <div class="product-button" style="margin-top: 50px;">
              <button type="edit" class="btn btn-primary" data-toggle="modal" data-target="#addInventoryModal"
                data-dismiss="modal" aria-label="Close" (click)="editProduct()">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                  <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                    <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path
                      d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
                  </g>
                </svg>
                Edit Product
              </button>
              <button type="archive" class="close btn btn-danger d-flex  justify-content-center align-items-center" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#deleteProduct" (click)="inventories.assetId=editInventoryId">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M7.616 20q-.667 0-1.141-.475T6 18.386V6h-.5q-.213 0-.356-.144T5 5.499t.144-.356T5.5 5H9q0-.31.23-.54t.54-.23h4.46q.31 0 .54.23T15 5h3.5q.213 0 .356.144t.144.357t-.144.356T18.5 6H18v12.385q0 .666-.475 1.14t-1.14.475zm2.692-3q.213 0 .357-.144t.143-.356v-8q0-.213-.144-.356T10.307 8t-.356.144t-.143.356v8q0 .213.144.356q.144.144.356.144m3.385 0q.213 0 .356-.144t.143-.356v-8q0-.213-.144-.356Q13.904 8 13.692 8q-.213 0-.357.144t-.143.356v8q0 .213.144.356t.357.144"/></svg>
                Delete
              </button>
            </div>
          }
           

          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Product -->
<div class="modal fade" id="deleteProduct" tabindex="-1" role="dialog" aria-labelledby="deleteProductTitle"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered delete-product-width" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close inven-cls" data-dismiss="modal" aria-label="Close">
        <svg class="cross-svg" width="14" height="12" viewBox="0 0 14 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5518 1.64453L1.85612 12.3402" stroke="#25252B" stroke-width="2.26313"
            stroke-linecap="round" />
          <path d="M12.5518 12.3401L1.85612 1.64445" stroke="#25252B" stroke-width="2.26313"
            stroke-linecap="round" />
        </svg>
      </button>
    </div>
    <div class="modal-body">
      <div class="delete-container">
        <svg width="104" height="89" viewBox="0 0 104 89" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M97.9349 88.6895H5.81325C1.64318 88.6895 -0.953878 84.1499 1.17867 80.5605L47.2395 2.89153C49.3298 -0.624 54.4184 -0.624 56.5087 2.89153L102.569 80.5605C104.691 84.1499 102.105 88.6895 97.9349 88.6895Z"
            fill="#FDCC27" />
          <path
            d="M49.0954 60.7142L47.1602 31.8952C46.9876 29.2877 48.9591 27.0254 51.5666 26.8528C54.1741 26.6802 56.4364 28.6517 56.609 31.2592C56.6272 31.4682 56.6181 31.6953 56.609 31.8952L54.6557 60.7142C54.3286 64.1667 49.4225 64.1667 49.0954 60.7142Z"
            fill="#030303" />
          <path
            d="M51.8757 75.0512C54.0083 75.0512 55.737 73.3225 55.737 71.1899C55.737 69.0574 54.0083 67.3286 51.8757 67.3286C49.7432 67.3286 48.0144 69.0574 48.0144 71.1899C48.0144 73.3225 49.7432 75.0512 51.8757 75.0512Z"
            fill="#030303" />
        </svg>
        <h3>Delete Product?</h3>
        <p>Are you sure you want to permanently delete the product?</p>
      </div>
      <div class="delete-button d-flex justify-content-center">
        <button type="delete" class="btn btn-danger" style="margin-right: 20px; width: 215px" (click)="deleteInventory(inventories.assetId)">
          Delete
        </button>
        <button type="archive" class="close btn goback-btn" data-dismiss="modal" aria-label="Close">Go Back</button>
      </div>
    </div>
  </div>
</div>
</div> 
}





<!-- Checked Out Inventories -->
<!-- <div class="checked-out-inv" style="overflow-x: auto">
    <table>
      <thead>
        <tr>
          <th scope="col">ASSET NAME</th>
          <th scope="col">USER</th>
          <th scope="col">PROJECT</th>
          <th scope="col">TAKEN ON</th>
          <th scope="col">APPROVED BY</th>
          <th scope="col">REQUESTED FOR</th>
          <th scope="col">DUES IN</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="d-flex align-items-center">
              <div class="inventory-img" data-target="#imagePreviewModal" data-toggle="modal">
                <img src="../../../../assets/inventories/drone.png" alt="" />
              </div>
              <p>DJI Mini 4 Pro Fly More Combo</p>
            </div>
          </td>
          <td>
            <p>Anoop Kumar</p>
          </td>
          <td class="project-name">
            <p>Jack & Jones</p>
          </td>
          <td>
            <p class="d-flex align-items-center">
              25 June 2024
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="margin-left: 15px">
                <rect width="40" height="40" rx="8" fill="#FDCC27" />
                <path
                  d="M19.9973 28.3379C17.113 28.3379 14.2286 28.3315 11.3443 28.3411C10.7956 28.3444 10.3029 28.2415 9.89552 27.8623C9.53744 27.5249 9.34362 27.1103 9.34033 26.6186C9.34033 24.1248 9.34033 21.6277 9.34033 19.1339C9.34033 17.2442 9.34362 15.3545 9.34033 13.4616C9.34033 12.43 10.1091 11.6588 11.1636 11.6588C15.8482 11.6588 20.5361 11.6588 25.2207 11.6588C26.4296 11.6588 27.6418 11.6588 28.8507 11.6588C29.7311 11.6588 30.444 12.2244 30.6247 13.0599C30.6707 13.2753 30.6575 13.497 30.6575 13.7155C30.6575 16.7846 30.6575 19.8537 30.6575 22.9196C30.6575 24.189 30.6641 25.4584 30.6411 26.7279C30.628 27.4767 30.0268 28.1355 29.2745 28.2962C29.1792 28.3154 29.084 28.3347 28.9821 28.3347C25.9861 28.3379 22.9933 28.3379 19.9973 28.3379ZM19.9842 26.9496C22.9112 26.9496 25.8415 26.9496 28.7686 26.9496C29.1135 26.9496 29.2285 26.8371 29.2285 26.4965C29.2285 22.158 29.2285 17.8227 29.2285 13.4841C29.2285 13.1788 29.107 13.0503 28.7982 13.0503C27.3593 13.0503 25.9204 13.0503 24.4782 13.0503C22.3002 13.0503 20.1221 13.0503 17.9441 13.0503C15.6938 13.0503 13.4435 13.0503 11.1964 13.0503C10.8876 13.0503 10.7661 13.1788 10.7661 13.4841C10.7661 14.1333 10.7661 14.7793 10.7661 15.4284C10.7661 19.1274 10.7661 22.8296 10.7661 26.5286C10.7661 26.8114 10.9008 26.9432 11.1833 26.9432C14.1169 26.9496 17.0505 26.9496 19.9842 26.9496Z"
                  fill="black" />
                <path
                  d="M19.9877 25.5645C17.4515 25.5645 14.9187 25.5613 12.3826 25.5677C12.2118 25.5677 12.1756 25.5227 12.1789 25.362C12.1888 24.7739 12.1855 24.1826 12.1822 23.5945C12.1822 23.5077 12.2085 23.4466 12.2709 23.3856C13.3911 22.2929 14.5114 21.2002 15.625 20.1012C15.7203 20.008 15.7663 20.024 15.8517 20.1076C16.3707 20.6218 16.8964 21.1295 17.4154 21.6437C17.491 21.7177 17.5337 21.7177 17.6092 21.6437C18.9069 20.3711 20.2078 19.0985 21.5087 17.8258C22.0376 17.3084 22.5698 16.791 23.0987 16.2704C23.1644 16.2061 23.2038 16.1772 23.2892 16.2608C24.7708 17.7166 26.259 19.1692 27.7471 20.6218C27.803 20.6764 27.8194 20.731 27.8194 20.805C27.8194 22.3411 27.8161 23.8805 27.8227 25.4166C27.8227 25.5677 27.7471 25.5613 27.642 25.5613C25.0895 25.5645 22.5369 25.5645 19.9877 25.5645Z"
                  fill="black" />
                <path
                  d="M14.3142 18.6068C13.1414 18.6068 12.1821 17.6748 12.1821 16.5436C12.1854 15.3674 13.1315 14.4418 14.324 14.445C15.5067 14.4482 16.4659 15.3834 16.4462 16.5468C16.4265 17.7455 15.441 18.6164 14.3142 18.6068Z"
                  fill="black" />
              </svg>
            </p>
          </td>
          <td>
            <p class="d-flex align-items-center">
              Mohd Shaizi
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="margin-left: 15px">
                <rect width="40" height="40" rx="8" fill="#FDCC27" />
                <path
                  d="M19.9973 28.3379C17.113 28.3379 14.2286 28.3315 11.3443 28.3411C10.7956 28.3444 10.3029 28.2415 9.89552 27.8623C9.53744 27.5249 9.34362 27.1103 9.34033 26.6186C9.34033 24.1248 9.34033 21.6277 9.34033 19.1339C9.34033 17.2442 9.34362 15.3545 9.34033 13.4616C9.34033 12.43 10.1091 11.6588 11.1636 11.6588C15.8482 11.6588 20.5361 11.6588 25.2207 11.6588C26.4296 11.6588 27.6418 11.6588 28.8507 11.6588C29.7311 11.6588 30.444 12.2244 30.6247 13.0599C30.6707 13.2753 30.6575 13.497 30.6575 13.7155C30.6575 16.7846 30.6575 19.8537 30.6575 22.9196C30.6575 24.189 30.6641 25.4584 30.6411 26.7279C30.628 27.4767 30.0268 28.1355 29.2745 28.2962C29.1792 28.3154 29.084 28.3347 28.9821 28.3347C25.9861 28.3379 22.9933 28.3379 19.9973 28.3379ZM19.9842 26.9496C22.9112 26.9496 25.8415 26.9496 28.7686 26.9496C29.1135 26.9496 29.2285 26.8371 29.2285 26.4965C29.2285 22.158 29.2285 17.8227 29.2285 13.4841C29.2285 13.1788 29.107 13.0503 28.7982 13.0503C27.3593 13.0503 25.9204 13.0503 24.4782 13.0503C22.3002 13.0503 20.1221 13.0503 17.9441 13.0503C15.6938 13.0503 13.4435 13.0503 11.1964 13.0503C10.8876 13.0503 10.7661 13.1788 10.7661 13.4841C10.7661 14.1333 10.7661 14.7793 10.7661 15.4284C10.7661 19.1274 10.7661 22.8296 10.7661 26.5286C10.7661 26.8114 10.9008 26.9432 11.1833 26.9432C14.1169 26.9496 17.0505 26.9496 19.9842 26.9496Z"
                  fill="black" />
                <path
                  d="M19.9877 25.5645C17.4515 25.5645 14.9187 25.5613 12.3826 25.5677C12.2118 25.5677 12.1756 25.5227 12.1789 25.362C12.1888 24.7739 12.1855 24.1826 12.1822 23.5945C12.1822 23.5077 12.2085 23.4466 12.2709 23.3856C13.3911 22.2929 14.5114 21.2002 15.625 20.1012C15.7203 20.008 15.7663 20.024 15.8517 20.1076C16.3707 20.6218 16.8964 21.1295 17.4154 21.6437C17.491 21.7177 17.5337 21.7177 17.6092 21.6437C18.9069 20.3711 20.2078 19.0985 21.5087 17.8258C22.0376 17.3084 22.5698 16.791 23.0987 16.2704C23.1644 16.2061 23.2038 16.1772 23.2892 16.2608C24.7708 17.7166 26.259 19.1692 27.7471 20.6218C27.803 20.6764 27.8194 20.731 27.8194 20.805C27.8194 22.3411 27.8161 23.8805 27.8227 25.4166C27.8227 25.5677 27.7471 25.5613 27.642 25.5613C25.0895 25.5645 22.5369 25.5645 19.9877 25.5645Z"
                  fill="black" />
                <path
                  d="M14.3142 18.6068C13.1414 18.6068 12.1821 17.6748 12.1821 16.5436C12.1854 15.3674 13.1315 14.4418 14.324 14.445C15.5067 14.4482 16.4659 15.3834 16.4462 16.5468C16.4265 17.7455 15.441 18.6164 14.3142 18.6068Z"
                  fill="black" />
              </svg>
            </p>
          </td>
          <td>
            <p>15 Days</p>
          </td>
          <td>
            <p>2 Days</p>
          </td>
          <td class="extend-due" data-toggle="modal" data-target="#extendRequest">
            <p>Extend Due Date</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div> -->
</div>

