import { style } from "@angular/animations";
import { Component, ViewChild, ElementRef , HostListener ,Renderer2} from "@angular/core";
import { NgModel, ReactiveFormsModule } from "@angular/forms";
// import PDFObject from "pdfobject";
import {
  FormGroup,
  FormsModule,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
} from "@angular/forms";
import { NgClass, NgStyle } from "@angular/common";
import { clippingParents } from "@popperjs/core";
import { InventoryService } from "src/app/service/inventory.service";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  alertModalDelete,
  alertModalWithoutConfirm,
  alertModalWithConfirm,
} from "src/app/component/helpers/alert";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZXingScannerModule } from '@zxing/ngx-scanner';


interface Category {
  value: string;
  viewValue: string;
}

interface AssignedInventory {
  value: string;
  viewValue: string;
}

interface AllInventory {
  value: string;
  viewValue;
}

interface OnlyAssigned {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-inventories",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    NgStyle,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ZXingScannerModule
  ],
  templateUrl: "./inventories.component.html",
  styleUrls: ["./inventories.component.css", "../styles/inventory.css"],
})
export class InventoriesComponent {
  // Mobile scanner
  scannerEnabled: boolean = false;
  scannedProductName: string = '';
  scannedProductId: string = '';


  openScannerModal() {
    this.scannerEnabled = true;
    const modalElement = document.getElementById('qrScannerModal');
    if (modalElement) {
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.style.backgroundColor = 'rgba(0,0,0,0.5)';
    }
  }

  closeScannerModal() {
    this.scannerEnabled = false;
    const modalElement = document.getElementById('qrScannerModal');
    if (modalElement) {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
    }
  }

  openModal() {
    const modalElement = document.getElementById('exampleModalCenter');
    if (modalElement) {
      this.renderer.addClass(modalElement, 'show');
      this.renderer.setStyle(modalElement, 'display', 'block');
    }
  }

  closeModal() {
    const modalElement = document.getElementById('exampleModalCenter');
    if (modalElement) {
      this.renderer.removeClass(modalElement, 'show');
      this.renderer.setStyle(modalElement, 'display', 'none');
    }
  }

  isShimmerLoading: boolean = true;
  shimmerLoadingValue = ([] = [1, 2, 3, 4 , 5]);

  categories: Category[] = [
    { value: "Drone", viewValue: "Drone" },
    { value: "Drone helipad", viewValue: "Drone helipad" },
    { value: "Battery", viewValue: "Battery" },
    { value: "Power Bank", viewValue: "Power Bank" },
    { value: "Graphic card", viewValue: "Graphic card" },
    { value: "Laptop", viewValue: "Laptop" },
    { value: "Mouse", viewValue: "Mouse" },
    { value: "Keyboard", viewValue: "Keyboard" },
    { value: "Earphone", viewValue: "Earphone" },
    { value: "Screen", viewValue: "Screen" },
    { value: "CPU", viewValue: "CPU" },
    { value: "RAM", viewValue: "RAM" },
    { value: "Hard Disk", viewValue: "Hard Disk" },
    { value: "HDMI To HDMI", viewValue: "HDMI To HDMI" },
    { value: "HDMI To Display Cable", viewValue: "HDMI To Display Cable" },
    { value: "OREI HDMI", viewValue: "OREI HDMI" },
    { value: "HDMI To MICRO HDMI", viewValue: "HDMI To MICRO HDMI" },
    { value: "HDMI To MINI HDMI", viewValue: "HDMI To MINI HDMI" },
    { value: "HDMI To VGI", viewValue: "HDMI To VGI" },
    {
      value: "4k Video Capture HDMI To USB",
      viewValue: "4k Video Capture HDMI To USB",
    },
    { value: "USB HUB", viewValue: "USB HUB" },
    {
      value: "TP link WIFI USB adaptor",
      viewValue: "TP link WIFI USB adaptor",
    },
    { value: "Oculus", viewValue: "Oculus" },
    { value: "Oculus sensor", viewValue: "Oculus sensor" },
    { value: "Oculus GO", viewValue: "Oculus GO" },
    { value: "INSTA 360", viewValue: "INSTA 360" },
    { value: "Extension", viewValue: "Extension" },
    { value: "Monitor", viewValue: "Monitor" },
    { value: "BENQ PENS", viewValue: "BENQ PENS" },
    { value: "Bosch Kit", viewValue: "Bosch Kit" },
    { value: "Charger + Cable", viewValue: "Charger + Cable" },
    { value: "Oculus Quest", viewValue: "Oculus Quest" },
    { value: "Security Camera", viewValue: "Security Camera" },
    { value: "Cable", viewValue: "Cable" },
    { value: "Touch Cable", viewValue: "Touch Cable" },
    { value: "USB Extension", viewValue: "USB Extension" },
    { value: "DVI To VGA", viewValue: "DVI To VGA" },
    { value: "Mira cast Dongle", viewValue: "Mira cast Dongle" },
    { value: "HDMI To SDI Card", viewValue: "HDMI To SDI Card" },
    { value: "Measure Tape", viewValue: "Measure Tape" },
    { value: "Board Clip", viewValue: "Board Clip" },
    { value: "Mini Tripod", viewValue: "Mini Tripod" },
    { value: "Mini Blower", viewValue: "Mini Blower" },
    { value: "Touch bar", viewValue: "Touch bar" },
    { value: "Smart Tv Box", viewValue: "Smart Tv Box" },
    {
      value: "Coller master 750 Box 650 SMPS",
      viewValue: "Coller master 750 Box 650 SMPS",
    },
    { value: "Jio Hotspot", viewValue: "Jio Hotspot" },
    { value: "Fire Tv stick", viewValue: "Fire Tv stick" },
    { value: "Fleet track device", viewValue: "Fleet track device" },
    { value: "Xbox Kinect + Aaptor", viewValue: "Xbox Kinect + Aaptor" },
    { value: "Charger", viewValue: "Charger" },
    { value: "Decklink HDMI Recorder", viewValue: "Decklink HDMI Recorder" },
    { value: "KVM Orie Adaptor", viewValue: "KVM Orie Adaptor" },
    { value: "KVM Orie USB 2.0", viewValue: "KVM Orie USB 2.0" },
    { value: "CORSAIR -VS 650", viewValue: "CORSAIR -VS 650" },
    { value: "VR MASK 150", viewValue: "VR MASK 150" },
    { value: "3D Advertising machine", viewValue: "3D Advertising machine" },
    { value: "HP IP Camera", viewValue: "HP IP Camera" },
    { value: "Intra Oral Camera", viewValue: "Intra Oral Camera" },
    { value: "Mini Keyboard", viewValue: "Mini Keyboard" },
    { value: "Headphones", viewValue: "Headphones" },
    { value: "Gaming remote", viewValue: "Gaming remote" },
    { value: "3D Demo car", viewValue: "3D Demo car" },
    { value: "Green screen", viewValue: "Green screen" },
    { value: "Router", viewValue: "Router" },
  ];
  assignedInv: AssignedInventory[] = [
    { value: "All Assigned", viewValue: " All Assigned" },
  ];

  allInv: AllInventory[] = [{ value: "Inventories", viewValue: "Inventories" }];
  onlyAssigned: OnlyAssigned[] = [{ value: "Assigned(Mine)", viewValue: "Assigned(Mine)" }];
  
  currentStep = 1;
  selectedImage: File | null = null;
  selectedImages: File[] = [];
  previewImageUrl: string | ArrayBuffer | null = null;
  previewImagesUrl: string[] = [];
  maxImages: number = 5;

  productForm: FormGroup;
  batteryForm: FormGroup;
  warrantyForm: FormGroup;
  qrForm: FormGroup;

  //To enable disable the warranty Form
  isWarrantyEnabled: boolean = false;
  isStorageEnabled: boolean = true;
  qrVisible = false;
  batteryFormVisible = false;
  warrantyFormVisible = false;
  productFormVisible: boolean;

  showConditionReason: boolean = false;
  showRentedDate: boolean = false;

  // recharge Cycle Dropdown
  numbers: number[] = Array.from({ length: 100 }, (_, i) => i + 1);
  userDepartment: string | null = null;
  showInventoryButton = false;

  users: { value: string; viewValue: string; id: string }[] = [];

  ngOnInit() {
    this.getAllUsers();
    this.getInventoryData();
    // this.getAssignInventory();
    // this.getAssignedInventory();
    this.batteryForm.valueChanges.subscribe((val) => console.log(val));
    this.userDepartment = localStorage.getItem("department");
    this.showInventoryButton = this.userDepartment === "HR";
  }

  assigned = [];
  totalAssigned: any;
  isAssigned: Boolean = false;
  getAssignedInventory() {
    const field: any = {
      pageNum: 1,
      limit: 20,
    }
    this.inventoryService.getAssigned(field).subscribe((res) => {
      this.assigned = res["data"].records.reverse();
      this.totalAssigned = res["data"].totalCounts;
      this.isAssigned = true;
      this.myAssigned = false;
      console.log("Assigned", res);
    });
  }


  ownAssigned : any[] = []
  myAssigned: Boolean = false
  totalMyAssigned : any;
  getOnlyAssignedInventory()
  {
    const field: any = {
      pageNum: 1,
      limit: 20,
    }
    this.inventoryService.getAssignInv(field).subscribe((res) =>{
      this.ownAssigned = res["data"].records.reverse();
      this. totalMyAssigned  = res["data"].totalCounts;
      this.myAssigned = true;
      this.isAssigned=false;
      console.log("Assigned", res);
    })
  } 
  constructor(
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {

    //sorting categories by alphabetical order
    this.categories.sort((a, b) => a.viewValue.localeCompare(b.viewValue));

    // Product Info Form
    this.productForm = this.fb.group({
      assetName: ["", [Validators.required, Validators.maxLength(50)]],
      productId: ["", [Validators.required, Validators.maxLength(25)]],
      location: ["", [Validators.required, Validators.maxLength(50)]],
      type: ["", Validators.required],
      productCost: ["", Validators.required],
      accessoriesInv: ["", Validators.maxLength(50)],
      rentedDate: [""],
      productFile: [],
    });
    // this.onConditionChange({ target: { value: this.productForm.get('condition')?.value } });
    this.onTypeChange({
      target: { value: this.productForm.get("type")?.value },
    });

    //Battery Info Form
    this.batteryForm = this.fb.group({
      batteryType: ["", Validators.required],
      storageValue: ["",[ Validators.required,Validators.maxLength(50)]],
      storageType: ["", Validators.required],
      recharge: this.fb.group({
        number: ["1", this.showRechargeCycle ? Validators.required : ""],
        cycle: ["days", this.showRechargeCycle ? Validators.required : ""],
      }),
      assetCategory: ["Drone", Validators.required],
      brand: ["", [Validators.required , Validators.maxLength(100)]],
      quantity: ["", Validators.required],
      size: ["", [Validators.required,Validators.maxLength(50)]],
    });

    //Warranty Info Form
    this.warrantyForm = this.fb.group({
      warranty: ["", Validators.required],
      expiryDate: [{ value: "", disabled: false }],
      contactPerson: [{ value: "", disabled: false }],
      phoneNumber: [{ value: "", disabled: false }],
      invoice: [{ value: "", disabled: false }],
    });

    this.qrForm = this.fb.group({
      qrCode: ["", Validators.required],
    });
  }

  patchExpiryDate(date: string): string {
    const parsedDate = new Date(date);
    const year = parsedDate.getFullYear();
    const month = ("0" + (parsedDate.getMonth() + 1)).slice(-2);
    const day = ("0" + parsedDate.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  isEditable: boolean = false;
  // Edit product
  editProduct() {
    this.currentStep = 1;
    this.isEdit = true;
    const rentedTillDate = this.patchExpiryDate(
      this.singleInventory.rentedTill,
    );
    const accessoryNames = this.singleInventory.accessories.map((item: { name: string }) => item.name).join(",");
    // Product Form Patching Values
    this.productForm.patchValue({
      assetName: this.singleInventory.name,
      productId: this.singleInventory.productId,
      location: this.singleInventory.location,
      type: this.singleInventory.type,
      productCost: this.singleInventory.cost,
      accessoriesInv: accessoryNames, 
      rentedDate: rentedTillDate,
      productFile: this.singleInventory.images,
    });
    console.log("Accessories",this.singleInventory.accessories);
    // Image Preview
    if (this.singleInventory.images && this.singleInventory.images.length > 0) {
      this.selectedImages = this.singleInventory.images;
      this.updateImagePreviews();
    }

    // Rental type enable
    this.showRentedDate = this.singleInventory.type === "Rental";
    const rentedDateControl = this.productForm.get("rentedDate");
    if (this.showRentedDate) {
      rentedDateControl?.setValidators([Validators.required]);
    } else {
      rentedDateControl?.clearValidators();
      rentedDateControl?.setValue("");
    }
    rentedDateControl?.updateValueAndValidity();
    console.log("Images", this.singleInventory.images);

    // Battery Form Patching Values
    this.batteryForm.patchValue({
      batteryType: this.singleInventory.batteryType,
      storageType: this.singleInventory.haveStorage ? "yes" : "No",
      assetCategory: this.singleInventory.assetCategory,
      brand: this.singleInventory.brand,
      quantity: this.singleInventory.quantity,
      size: this.singleInventory.size,
    });
    //Battery Type Enable
    this.showRechargeCycle = this.singleInventory.batteryType === "Chargeable";
    if (this.showRechargeCycle) {
      this.batteryForm.patchValue({
        recharge: {
          number: this.singleInventory.rechargeCycle,
          cycle: "Days",
        },
      });
    }
    const rechargeControl = this.batteryForm.get("recharge");
    if (!this.showRechargeCycle) {
      rechargeControl?.clearValidators();
    } else {
      rechargeControl?.setValidators([Validators.required]);
    }
    rechargeControl?.updateValueAndValidity();

    this.isStorageEnabled = this.singleInventory.haveStorage === true || this.singleInventory.selectedStorage === "yes";
    if (this.isStorageEnabled) {
      this.batteryForm.patchValue({
        storageValue: this.singleInventory.storageVolume,
      });
      this.batteryForm.get("storageValue")?.enable();
    } else {
      this.batteryForm.patchValue({
        storageValue: "",
      });
      this.batteryForm.get("storageValue")?.disable();
    }

    // Warranty Form Patching Values
    if (this.singleInventory.haveWarranty && this.singleInventory.warranty) {
      const expiryDateISO = this.patchExpiryDate(
        this.singleInventory.warranty.expiryDate,
      );

      const invoiceFile = this.singleInventory.warranty.invoice;
      console.log("InvoiceFile", invoiceFile);

      this.warrantyForm.patchValue({
        warranty: "yes",
        expiryDate: expiryDateISO,
        contactPerson: this.singleInventory.warranty.contactPerson,
        phoneNumber: this.singleInventory.warranty.phoneNumber,
        invoice: invoiceFile,
      });


      console.log("Expiry", expiryDateISO);
      this.isWarrantyEnabled = true;
      this.warrantyForm.get("expiryDate")?.enable();
      this.warrantyForm.get("contactPerson")?.enable();
      this.warrantyForm.get("phoneNumber")?.enable();
      this.warrantyForm.get("invoice")?.enable();
      if (invoiceFile) {
        this.loadInvoicePreview(invoiceFile);
      }
    } else {
      this.warrantyForm.patchValue({
        warranty: "No",
        expiryDate: null,
        contactPerson: "",
        phoneNumber: "",
        invoice: null,
      });
      this.isWarrantyEnabled = false;
      this.warrantyForm.get("expiryDate")?.disable();
      this.warrantyForm.get("contactPerson")?.disable();
      this.warrantyForm.get("phoneNumber")?.disable();
      this.warrantyForm.get("invoice")?.disable();
    }
    console.log("Warranty Enabled:", this.isWarrantyEnabled);
  }


  loadInvoicePreview(invoiceFileUrl: string): void {
    if (
      invoiceFileUrl.endsWith(".png") ||
      invoiceFileUrl.endsWith(".jpg") ||
      invoiceFileUrl.endsWith(".jpeg")
    ) {
      this.imagePreviewUrl = invoiceFileUrl;
      this.pdfPreviewUrl = null;
      this.filePreviewUrl = null;
    } else if (invoiceFileUrl.endsWith(".pdf")) {
      // console.log("show pdf", PDFObject);
      // setTimeout(() => {
      //   PDFObject.embed(invoiceFileUrl, "#my-pdf");
      // }, 5000);
      this.pdfPreviewUrl =
      this.sanitizer.bypassSecurityTrustResourceUrl(invoiceFileUrl);
      this.imagePreviewUrl = null;
      this.filePreviewUrl = null;
    } else {
      this.filePreviewUrl = invoiceFileUrl;
      this.imagePreviewUrl = null;
      this.pdfPreviewUrl = null;
    }
  }

  editInventoryId: string;
  singleInventory: any;
  getSingleInventory(id: any) {
    this.editInventoryId = id;
    this.inventoryService.getInventoryById(id).subscribe((res) => {
      this.singleInventory = res["data"];
      console.log("Inventory BY ID", this.singleInventory);
      if (this.singleInventory) {
        this.editProduct();
      }
    });
  
  }

  // Delete Inventory
  deleteInventory(id: any) {
        this.editInventoryId = id;
          this.inventoryService.deleteInventory(id).subscribe(
            (res) => {
              window.location.reload();
            },
            (error) => {
              console.error(error);
              const message = error.error.message || "Something went wrong!";
              alertModalDelete(message);
            },
          );
  } 

  //Product Condition Change
  onConditionChange(event: any): void {
    const conditionValue = event.target.value;
    this.showConditionReason = conditionValue === "Not Working";
    if (!this.showConditionReason) {
      this.productForm.get("conditionReason")?.setValue("");
    }
  }

  //Battery Type Change
  showRechargeCycle: boolean = false;
  onBatteryTypeChange(event: any): void {
    const batteryTypeValue = event.target.value;
    this.showRechargeCycle = batteryTypeValue === "Chargeable";
    if (!this.showRechargeCycle) {
      console.log({ showRechargeCycle: this.showRechargeCycle });

      // this.batteryForm.get('number').removeValidators;
      // this.batteryForm.get('cylcle').removeValidators;
    }
  }

  onStorageTypeChange(event: any): void {
    const selectedStorage = event.target.value;
    this.isStorageEnabled = selectedStorage === "yes";

    if (this.isStorageEnabled) {
      this.batteryForm.get("storageValue")?.enable();
    } else {
      this.batteryForm.get("storageValue")?.disable();
    }
  }

  onWarrantyTypeChange(event: any): void {
    console.log("Before change, isEdit: ", this.isEdit);
    const selectedWarranty = event.target.value;
    this.isWarrantyEnabled = selectedWarranty === "yes";
    console.log('Warranty enabled:', this.isWarrantyEnabled);

    if (this.isWarrantyEnabled) {
      this.warrantyForm.get("expiryDate")?.enable();
      this.warrantyForm.get("contactPerson")?.enable();
      this.warrantyForm.get("phoneNumber")?.enable();
      this.warrantyForm.get("invoice")?.enable();
    } else {
      this.warrantyForm.get("expiryDate")?.disable();
      this.warrantyForm.get("contactPerson")?.disable();
      this.warrantyForm.get("phoneNumber")?.disable();
      this.warrantyForm.get("invoice")?.disable();

      this.warrantyForm.patchValue({
        expiryDate: null,
        contactPerson: "",
        phoneNumber: "",
        invoice: "",
      });
    }
  }

  //Product Type Change
  onTypeChange(event: any): void {
    const typeValue = event.target.value;
    this.showRentedDate = typeValue === "Rental";

    const rentedDateControl = this.productForm.get("rentedDate");
    if (this.showRentedDate) {
      rentedDateControl?.setValidators([Validators.required]);
    } else {
      rentedDateControl?.clearValidators();
      rentedDateControl?.setValue("");
    }
    rentedDateControl?.updateValueAndValidity();
  }

  // Product Info Image Upload
  // onSelectImage(event: any): void {
  //   if (event.target.files && event.target.files[0]) {
  //     const selectedImage = event.target.files[0];
  //     this.selectedImages = [selectedImage];
  //     this.updateImagePreviews();
  //     console.log("selected images", this.selectedImages);
  //     this.productForm.patchValue({
  //       productFile: this.selectedImages,
  //     });
  //   }
  //   console.log(this.selectedImage);
  // }

  // // Product Info Multiple Image Upload
  // onSelectMultipleImages(event: any): void {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const filesArray: File[] = Array.from(event.target.files) as File[];
  //     const totalImages = this.selectedImages.length + filesArray.length;

  //     if (totalImages > this.maxImages) {
  //       return alertModalWithoutConfirm(
  //         "warning",
  //         `You can only upload a maximum of 4 images.`,
  //       );
  //     }
  //     this.selectedImages = [...this.selectedImages, ...filesArray];
  //     console.log("selected images", this.selectedImages);

  //     this.updateImagePreviews();
  //     this.productForm.patchValue({
  //       productFile: this.selectedImages,
  //     });
  //   }
  //   console.log(this.selectedImage);
  // }

  // updateImagePreviews(): void {
  //   this.previewImagesUrl = [];
  //   this.selectedImages.forEach((image) => {
  //     if (typeof image === "string") {
  //       this.previewImagesUrl.push(image);
  //     } else {
  //       const reader = new FileReader();
  //       reader.onload = (e: any) => {
  //         this.previewImagesUrl.push(e.target.result);
  //       };
  //       reader.readAsDataURL(image);
  //     }
  //   });
  // }

  // Remove multiple uploaded Image
  removeMultipleImage(index: number): void {
    this.selectedImages.splice(index, 1);
    this.updateImagePreviews();
    this.productForm.patchValue({
      productFile: this.selectedImages,
    });
  }

  submitted = false;
  //Product submit
  onSubmitProduct(): void {
    console.log(this.productForm);
    this.submitted = true;

    if (this.productForm.valid) {
      this.currentStep = 2;
      const formData = new FormData();
      Object.keys(this.productForm.value).forEach((key) => {
        formData.append(key, this.productForm.value[key]);
      });

      if (this.selectedImages.length > 0) {
        this.selectedImages.forEach((image, index) => {
          formData.append(`files[${index}]`, image);
        });
      }
    } else if (this.productForm.invalid) {
      console.log("invalid");
      return alertModalWithoutConfirm(
        "warning",
        "Please fill in all required fields before submitting.",
      );
    }
  }

  //Battery Submit
  onSubmitBattery() {
    console.log(this.batteryForm);
    this.submitted = true;
    if (this.batteryForm.valid) {
      this.currentStep = 3;
    } else if (this.batteryForm.invalid) {
      console.log("invalid");
      return alertModalWithoutConfirm(
        "warning",
        "Please fill in all required fields before submitting.",
      );
    }
  }

  //Warranty Info Invoice File upload
  imagePreviewUrl: string | ArrayBuffer | null = null;
  pdfPreviewUrl: SafeResourceUrl | null = null;
  filePreviewUrl: string | ArrayBuffer | null = null;
  fileName: string | null = null;
  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (file.type.includes("image")) {
          this.imagePreviewUrl = e.target.result;
          this.pdfPreviewUrl = null;
          this.filePreviewUrl = null;
        } else if (file.type.includes("pdf")) {
          this.pdfPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            e.target.result,
          );
          this.imagePreviewUrl = null;
          this.filePreviewUrl = null;
        } else if (
          file.type.includes("msword") ||
          file.type.includes(
            "vnd.openxmlformats-officedocument.wordprocessingml.document",
          ) ||
          file.type.includes("vnd.ms-excel") ||
          file.type.includes(
            "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          )
        ) {
          this.filePreviewUrl = e.target.result;
          this.imagePreviewUrl = null;
          this.pdfPreviewUrl = null;
        }
      };

      reader.readAsDataURL(file);
      this.warrantyForm.patchValue({
        invoice: file,
      });
      this.warrantyForm.get("invoice")?.enable();
    } else {
      this.imagePreviewUrl = null;
      this.pdfPreviewUrl = null;
      this.filePreviewUrl = null;
      this.fileName = null;
    }
  }

  //Formatted Expiry DATE
  formatExpiryDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  //Formatted Rented DATE
  formatRentedDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  }

  navigateToStep(step: number): void {
    this.currentStep = step;
  }

  // Price Formatting
  formatCurrency(value: string | number): string {
    if (!value) return "";
    return new Intl.NumberFormat("en-IN").format(Number(value));
  }
  onProductCostInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.replace(/,/g, "");
    this.productForm.get("productCost")?.setValue(value, { emitEvent: false });
    input.value = this.formatCurrency(value);
  }

 // For edit mode
newImages: File[] = []; 

onSelectImage(event: any): void {
  if (event.target.files && event.target.files[0]) {
    const selectedImage = event.target.files[0];

    this.newImages = [selectedImage];
    this.selectedImages = [...this.selectedImages, ...this.newImages];

    this.updateImagePreviews();

    this.productForm.patchValue({
      productFile: this.selectedImages,
    });
    console.log("Selected images (including existing):", this.selectedImages);
  }
}

// Multiple Image Selection
onSelectMultipleImages(event: any): void {
  if (event.target.files && event.target.files.length > 0) {
    const filesArray: File[] = Array.from(event.target.files) as File[];
    const totalImages = this.selectedImages.length + filesArray.length;

    if (totalImages > this.maxImages) {
      return alertModalWithoutConfirm(
        "warning",
        "You can only upload a maximum of 4 images."
      );
    }

    
    this.newImages = filesArray;
    this.selectedImages = [...this.selectedImages, ...this.newImages];

  
    this.selectedImages = Array.from(new Set(this.selectedImages));

    this.updateImagePreviews();
    this.productForm.patchValue({
      productFile: this.selectedImages,
    });
    console.log("Selected images (including existing):", this.selectedImages);
  }
}

// Preview Image Updates
updateImagePreviews(): void {
  this.previewImagesUrl = [];
  this.selectedImages.forEach((image) => {
    if (typeof image === "string") {
      this.previewImagesUrl.push(image);
    } else {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewImagesUrl.push(e.target.result);
      };
      reader.readAsDataURL(image);
    }
  });
}

  // Remove multiple uploaded Image
// removeImage(index: number): void {
//     this.selectedImages.splice(index, 1);
//     this.updateImagePreviews();
//     this.productForm.patchValue({
//       productFile: this.selectedImages,
//     });
// }


// Remove multiple uploaded images
removeImage(index: number ): void {
  const removedImage = this.selectedImages[index]; 
  const body = {
    inventoryId: this.editInventoryId, 
    images: [removedImage], 
  };

  this.inventoryService.deleteInventoryImage(body).subscribe(
    (response) => {
      console.log('Image removed successfully from server:', response);

      this.selectedImages.splice(index, 1);
      this.updateImagePreviews();
      this.productForm.patchValue({
        productFile: this.selectedImages,
      });
    },
    (error) => {
      console.error('Failed', error);
    }
  );
}





  allformData: any = {};
  //Warranty Submit
  qrCodeImageUrl: string;
  qrcode: any[] = [];
  onSubmitWarranty() {
    console.log("isEdit value:", this.isEdit);

    if (
      this.productForm.valid &&
      this.batteryForm.valid &&
      this.warrantyForm.valid
    ) {
      const allFormValue = {
        ...this.productForm.value,
        ...this.batteryForm.value,
        ...this.warrantyForm.value,
      };

      this.allformData = allFormValue;
      console.log(allFormValue);5

      const expiryDate = new Date(allFormValue.expiryDate);
      const formattedExpiryDate = this.formatExpiryDate(expiryDate);

      const rentedDate =
        allFormValue.type === "Rental"
          ? new Date(allFormValue.rentedDate)
          : null;
      const formattedRentedDate = rentedDate
        ? this.formatRentedDate(rentedDate)
        : null;

      const getFormValue: any = {
        name: String(allFormValue.assetName || ""),
        available: Boolean(allFormValue.available),
        cost: allFormValue.productCost || "",
        accessories: allFormValue.accessoriesInv
          ? allFormValue.accessoriesInv.split(",")
          : [],
        assetCategory: allFormValue.assetCategory || "",
        batteryType: allFormValue.batteryType || "",
        haveStorage: Boolean(allFormValue.storageType === "yes" ? true : false),
        // storageVolume: allFormValue.storageValue,
        haveWarranty: Boolean(allFormValue.warranty === "yes" ? true : false),

        productId: String(allFormValue.productId || ""),
        location: String(allFormValue.location || ""),
        type: allFormValue.type,
        brand: String(allFormValue.brand || ""),
        size: allFormValue.size,
        quantity:
          allFormValue.quantity !== undefined
            ? Number(allFormValue.quantity)
            : 0,
      };

      if (allFormValue.type === "Rental") {
        getFormValue.rentedTill = formattedRentedDate;
      }
      if (this.showRechargeCycle) {
        getFormValue.recharge = {
          number: allFormValue.recharge.number || 0,
          cycle: "days",
        };
      }
      if (this.isStorageEnabled) {
        getFormValue.storageVolume = allFormValue.storageValue;
      }
      if (this.isEdit && this.editInventoryId) {
        getFormValue.assetId = this.editInventoryId;
      }
      if (this.isWarrantyEnabled) {
        console.log(this.isWarrantyEnabled, "Abbbb");
        getFormValue.warranty = {
          expiry: formattedExpiryDate,
          contactPerson: allFormValue.contactPerson || "",
          phoneNumber: allFormValue.phoneNumber || "",
        };
      }
      const formData = new FormData();
      const appendFormData = (data: any, parentKey?: string) => {
        if (data && typeof data === "object" && !Array.isArray(data)) {
          Object.keys(data).forEach((key) => {
            appendFormData(data[key], parentKey ? `${parentKey}[${key}]` : key);
          });
        } else if (Array.isArray(data)) {
          data.forEach((item, index) => {
            appendFormData(item, `${parentKey}[${index}]`);
          });
        } else {
          if (parentKey === "cost") {
            formData.append(parentKey, String(data));
          } else if (parentKey) {
            formData.append(parentKey, data);
          }
        }
      };
      console.log(getFormValue, "values");
      appendFormData(getFormValue);

    const selectedImages = this.productForm.get("productFile")?.value;
     if (selectedImages && Array.isArray(selectedImages)) {
    let newIndex = 0;
    selectedImages.forEach(async (image: File | string) => {
      if (typeof image === "string") {
        const blob = await fetch(image).then(res => res.blob());
        formData.append(`files[${newIndex}]`, blob, "blob");
      } else {
        formData.append(`files[${newIndex}]`, image, image.name);
      }
      newIndex++; 
    });
  }
      

      // Append the invoice file
      const invoice = this.warrantyForm.get("invoice")?.value;
      if (invoice && invoice instanceof File) {
        formData.append("warrantyInvoice", invoice, invoice.name);
      }
      if (!this.isEdit) {
        const productId = allFormValue.productId;
        const isDuplicate = this.inventory.some(
          (item) => item.productId === productId
        );
    
        if (isDuplicate) {
          return alertModalWithoutConfirm(
            "warning",
            "Product ID Already Exist",
          );
        }
        this.inventoryService.addInventory(formData).subscribe(
          (response) => {
            this.currentStep = 4;
            this.getInventoryData();
            this.qrcode = response.data.qrCode;
            
            console.log(this.qrCodeImageUrl, "QR code");
          },
          (error) => {
            console.error("error:", error);
          },
        );
      }
       else {
        console.log("Updating");
        this.inventoryService.updateInventory(formData).subscribe(
          (response) => {
            this.currentStep = 4;
            this.getInventoryData();
            this.qrcode = response.data.qrCode;
           
            console.log(this.qrCodeImageUrl, "QR code");
          },
          (error) => {
            console.error("error:", error);
          },
        );
      }
    }
    console.log("One or more forms are invalid");
  }

  //Download QR Code
  downloadQRCode() {
    const link = document.createElement("a");
    link.href = this.qrCodeImageUrl;
    link.download = "qr-code.png";
    link.click();
  }

  //Print QR Code
  printQRCode() {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Print QR Code</title>
          </head>
          <body>
            <img src="${this.qrCodeImageUrl}" alt="QR Code">
            <script>
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                };
              };
            </script>
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  }

  // isEditMode: boolean;
  isEdit : boolean = false;
  previewProductImg: any;
  productDetail: any;
  openProductDetail(index: number) {
    this.isEdit = true;
    this.previewProductImg = this.inventory[index];
    this.productDetail = this.inventory[index];
    console.log({ Product: this.productDetail });
  }

  resetClose() {
    this.productForm.reset();
    this.batteryForm.reset();
    this.warrantyForm.reset();
    this.currentStep = 1;
    this.selectedImages = [];
    this.previewImagesUrl =[];
    // this.isEditMode = false;
    this.isEdit = false;
    const batteryTypeValue = this.batteryForm.get("batteryType")?.value;
    this.showRechargeCycle = batteryTypeValue === "Chargeable";
    if (!this.showRechargeCycle) {
      this.batteryForm.get("recharge")?.reset("");
    }
  }

  downloadInvoice(url: string) {
    window.open(url, "_blank");
  }

  download(url: string) {
    window.open(url, "_blank");
  }
  previewInventory: any;
  openPreviewSlider(index: number) {
    this.previewInventory = this.inventory[index];
  }

  loggedInUsername = localStorage.getItem("username");
  inventory: any[] = [];
  totalInventory: number;
  inventoryAccessory: any;
  barCodeImg: any;
  searchQuery: string;
  isAllInventory: boolean = false;
  getInventoryData() {
    const field: any = {
      pageNum: 1,
      limit: 20,
      searchQuery: this.searchQuery,
      available:true,
    };
  
    this.inventoryService.getAllInventory(field).subscribe(
      (response: any) => {
        this.inventory = response.data.records.reverse();
        this.totalInventory = response.data.totalCounts;
        this.qrCodeImageUrl = response.data.qrCode;
        console.log("Inventory Response", this.inventory);
        this.isAllInventory = true;
        this.isAssigned = false;
        this.myAssigned = false;
        this.isShimmerLoading = false;
        // this.getAssignInventory();
      },
      (error) => {
        console.error("Error", error);
      },
    );
  }

  searchedInventory = [];
  totalSearchedInv: any;
  onSearchInventory() {
    const field: any = {
      pageNum: 1,
      limit: 100,
      searchQuery: this.searchQuery,
    };
    if (this.searchQuery === "") {
      this.clearSearch();
    } else {
      this.inventoryService
        .getSearchedInventory(field)
        .subscribe((response) => {
          console.log(response);
          this.searchedInventory = response["data"].records;
          this.inventory = this.searchedInventory;
          this.totalSearchedInv = response["data"].totalCounts;
          this.totalInventory = this.totalSearchedInv;
        });
    }
  }

  clearSearch() {
    this.searchQuery = "";
    this.getInventoryData();
  }

  getAllUsers(): void {
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.inventoryService.getInvUsers().subscribe(
      (res) => {
        let data = res["data"];
        this.users = data.map((user: any) => ({
          value: user.userName,
          viewValue: user.userName,
          id: user._id,
        }));
        console.log("users", this.users);
        if (loggedInUsername && loggedInUserId) {
          this.users.push({
            value: loggedInUsername,
            viewValue: loggedInUsername,
            id: loggedInUserId,
          });
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }

  assignInventory(users: any, inventories: any) {
    console.log({ users, inventories });

    const assignData = {
      userName: users?.value,
      userId: users?.id,
      assetId: inventories?.assetId,
    };
    this.inventoryService.assignInv(assignData).subscribe((response) => {
      console.log("assigned called");
      this.getInventoryData();
    });
  }


  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    // if(!this.isAssigned){
    // return this.inventory.slice(startIndex, endIndex);
    // }
    if(this.myAssigned){
      return this.ownAssigned.slice(startIndex, endIndex);
    }
    if(this.isAssigned){
      return this.assigned.slice(startIndex, endIndex);
    }
  }

  // Function to handle page change
  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option = {
      pageNum: page,
      limit: 20,
      available:true,
    };

  if(this.myAssigned){
    this.inventoryService.getAssignInv(option).subscribe((res) =>{
      this.ownAssigned = res["data"].records;
      this. totalMyAssigned  = res["data"].totalCounts;
      this.isShimmerLoading = false;
      });
  }
  if(this.isAssigned){
    this.inventoryService.getAssigned(option).subscribe(
      (res: any) => {
        this.assigned = res.data.records.reverse();
        this.totalAssigned = res.data.totalCounts;
        this.isShimmerLoading = false;
  }
    )}
if(!this.isAssigned){
  this.inventoryService.getAllInventory(option).subscribe(
    (response: any) => {
      this.inventory = response.data.records;
      this.totalInventory = response.data.totalCounts;
      this.isShimmerLoading = false;
    },
  );
}
}

  get totalPages(): number {
  if(this.myAssigned){
    return Math.ceil(this.totalMyAssigned / this.itemsPerPage);
  }
  if(this.isAssigned){
    return Math.ceil(this.totalAssigned / this.itemsPerPage);
  }
  if(!this.isAssigned){
    return Math.ceil(this.totalInventory / this.itemsPerPage);
  }
}
  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index,
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }
}
