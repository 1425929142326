import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private adminRequestCount = new BehaviorSubject<number>(0);
  adminRequestCount$ = this.adminRequestCount.asObservable();

  updateAdminRequestCount(count: number) {
    this.adminRequestCount.next(count);
  }
  constructor(private httpClient: HttpClient) { }

  new_inventory_url = environment.host + "inventory";
  get_inv_users = environment.host + "users";
  new_workstation_url = environment.host + "workstation";
  request_action_url = environment.host + "request/"
  user_inv_action_url = environment.host + "user-inventory/"
  get_admin_req_url = environment.host + "admin-request";
  admin_all_action_url = this.get_admin_req_url + "/take-action"


  // new_inv_req_url = environment.host + "request/new-inventory";
  // add_inv_req_url = environment.host + "request/inventory";
  // add_forward_req_url = environment.host + "request/forward/new-inventory";
  // assign_url = environment.host + "user-inventory/assign";
  // add_service_req_url = environment.host + "request/service";
  // add_forward_service_url = environment.host + "request/forward/service";
  // add_assigned_return_url = environment.host + "request/return";
  // add_assigned_extend_url = environment.host + "user-inventory/extend-request"
  // get_pending_req_url = environment.host + "user-inventory/pending-request";
  // add_overdue_extend_url = environment.host + "user-inventory/extend";
  // extend_url = environment.host + 'request/extend';
  // get_approved_url = environment.host + 'user-inventory/approved-request'




  getInvUsers(): Observable<any> {
    return this.httpClient.get<any>(this.get_inv_users)
  }

  addInventory(inventoryData: FormData): Observable<any> {
    return this.httpClient.post(this.new_inventory_url, inventoryData);
  }

  updateInventory(inventoryData: FormData): Observable<any> {
    return this.httpClient.patch(this.new_inventory_url, inventoryData);
  }
  updateWorkstation(id,inventoryData:any): Observable<any> {
    return this.httpClient.patch(this.new_workstation_url + "/" + id, inventoryData);
  }
  removeWorkstationAssignee(body){
    return this.httpClient.patch(this.new_workstation_url + "/remove-assignee", body);
  }

  uploadFiles(fileFormData: FormData, id) {
    return this.httpClient.post(this.new_workstation_url + "/add-image" + "/" + id, fileFormData);
  }

  getAllInventory(field) {
    const get_inventory_url = environment.host + 'inventory?' + `${field.searchQuery && field.searchQuery !== undefined ? `&searchQuery=${field.searchQuery}` : ''}${field.type && field.type !== undefined ? `&type=${field.type}` : ''}&available=${field.available}&page=${field.pageNum}&limit=${field.limit}`
    return this.httpClient.get(get_inventory_url);
  }

  getInventoryById(id) {
    return this.httpClient.get(this.new_inventory_url + "/" + id);
  }
  getWorkstationById(id){
    return this.httpClient.get(this.new_workstation_url + "/" + id);
  }
  deleteInventory(id) {
    return this.httpClient.delete(this.new_inventory_url + "/" + id);
  }
  getSearchedInventory(field) {
    const get_search_inv_url = environment.host + 'inventory?' + `${field.searchQuery && field.searchQuery !== undefined ? `&searchQuery=${field.searchQuery}` : ''}&page=${field.pageNum}&limit=${field.limit}`
    return this.httpClient.get(get_search_inv_url)
  }
  getSearchedServiceModalInventory(field) {
    const get_search_inv_url = environment.host + 'user-inventory/assign?' + `${field.searchQuery && field.searchQuery !== undefined ? `&searchQuery=${field.searchQuery}` : ''}&page=${field.pageNum}&limit=${field.limit}`
    return this.httpClient.get(get_search_inv_url)
  }
  
  getSearchedWorkstation(field) {
    const get_search_inv_url = environment.host + 'workstation?' + `${field.searchQuery && field.searchQuery !== undefined ? `&searchQuery=${field.searchQuery}` : ''}&page=${field.pageNum}&limit=${field.limit}`
    return this.httpClient.get(get_search_inv_url)
  }

  addWorkstation(workstationData: FormData): Observable<any> {
    return this.httpClient.post(this.new_workstation_url, workstationData)
  }

  getWorkstation(field: any) {
    let get_workstation_url = `${environment.host}workstation?page=${field.pageNum}&limit=${field.limit}`;
    if (field.name) {
      get_workstation_url += `searchQuery=${field.name}`;
    }
    return this.httpClient.get(get_workstation_url)
  }

  deleteWorkstation(id) {
    return this.httpClient.delete(this.new_workstation_url + "/" + id);
  }
  newInventoryRequest(newData: any) {
    return this.httpClient.post(this.request_action_url + "new-inventory", newData)
  }

  getNewInventoryRequest(field:any) {
    const statusQuery = field.status ? `&status=${field.status}` : '';
    return this.httpClient.get(`${this.request_action_url}new-inventory?${field.searchQuery && field.searchQuery !== undefined ? `&searchQuery=${field.searchQuery}` : ''}page=${field.pageNum}&limit=${field.limit}${statusQuery}`)
  }

  addInventoryRequest(invData: any) {
    return this.httpClient.post(this.request_action_url + "inventory", invData)
  }

  getAllInventoryRequest(field: any) {
    const statusQuery = field.status ? `&status=${field.status}` : '';
    return this.httpClient.get(`${this.request_action_url}inventory?${field.searchQuery && field.searchQuery !== undefined ? `&searchQuery=${field.searchQuery}` : ''}page=${field.pageNum}&limit=${field.limit}${statusQuery}`)
  }

  forwardRequest(forwardData: any) {
    return this.httpClient.post(this.request_action_url + "forward/new-inventory", forwardData)
  }
  assignInv(assignData: any) {
    return this.httpClient.post(this.user_inv_action_url + "assign", assignData)
  }

  getAssignInv(field: any) {
    let assigned_url = `${environment.host}user-inventory/assign?page=${field.pageNum}&limit=${field.limit}`;
    return this.httpClient.get(assigned_url);
  }

  getAssigned(field: any) {
    return this.httpClient.get(`${this.new_inventory_url}/assigned?page=${field.pageNum}&limit=${field.limit}`)
  }

  addServiceRequest(serviceData: any) {
    return this.httpClient.post(this.request_action_url + "service", serviceData);
  }

  // getServiceRequest(field: any) {
  //   return this.httpClient.get(`${this.request_action_url}service?page=${field.pageNum}&limit=${field.limit}`)
  // }




  getServiceRequest(field: any) {
    const statusQuery = field.status ? `&status=${field.status}` : '';
    return this.httpClient.get(`${this.request_action_url}service?${field.searchQuery && field.searchQuery !== undefined ? `&searchQuery=${field.searchQuery}` : ''}page=${field.pageNum}&limit=${field.limit}${statusQuery}`);
  }
  

  addForwardServiceReq(forwardData: any) {
    return this.httpClient.post(this.request_action_url + "forward/service", forwardData);
  }

  getAllForwardedReq(reqData: any ,field:any) {
    return this.httpClient.post(`${this.request_action_url}forward?page=${field.pageNum}&limit=${field.limit}`, reqData)
  }

  addAssignedReturn(returnData: any) {
    return this.httpClient.post(this.request_action_url + "return", returnData);
  }

  addApprovedImages(returnData: any) {
    return this.httpClient.post(this.user_inv_action_url + "submit-image", returnData);
  }


  assignedExtendRequest(extendData: any) {
    return this.httpClient.post(this.user_inv_action_url + "extend-request", extendData)
  }

  adminAction(approvedData: any) {
    return this.httpClient.post(this.request_action_url + "take-action", approvedData)
  }
  superAdminAction(approvedData: any) {
    return this.httpClient.post(this.get_admin_req_url +"/" + "take-action", approvedData)
  }
  getPendingRequest(pendingData: any, field:any) {
    return this.httpClient.post(`${this.user_inv_action_url}pending-request?page=${field.pageNum}&limit=${field.limit}`, pendingData)
  }

  getApprovedRequest(approvedData: any ,field:any) {
    return this.httpClient.post(`${this.user_inv_action_url}approved-request?page=${field.pageNum}&limit=${field.limit}`, approvedData)

  }
  getDeclinedRequest(declinedData: any ,field:any) {
    return this.httpClient.post(`${this.user_inv_action_url}declined-request?page=${field.pageNum}&limit=${field.limit}`, declinedData)
  }
  getOverdueAssets(field: any) {
    let overdue_asset_url = `${environment.host}user-inventory/overdue-asset?page=${field.pageNum}&limit=${field.limit}`;
    return this.httpClient.get(overdue_asset_url);
  }
  getInvReturnRequest(field: any) {
    const statusQuery = field.status ? `&status=${field.status}` : '';
    return this.httpClient.get(`${this.request_action_url}return?page=${field.pageNum}&limit=${field.limit}${statusQuery}`)
  }
  overdueExtendRequest(extendData: any) {
    return this.httpClient.post(this.user_inv_action_url + "extend", extendData)
  }

  getExtendRequest(field: any) {
    return this.httpClient.get(`${this.request_action_url}extend?page=${field.pageNum}&limit=${field.limit}`)
  }
  getInventoryOverview() {
    return this.httpClient.get(this.new_inventory_url + "/overview")
  }
  getAssetReturnRequest(field: any) {
    let get_asset_return_url = `${environment.host}user-inventory/return-request?page=${field.pageNum}&limit=${field.limit}`;
    return this.httpClient.get(get_asset_return_url)
  }
  getAdminRequest(adminReqData: any) {
    return this.httpClient.post(this.get_admin_req_url, adminReqData)
  }
  deleteInventoryImage(body:any){
    return this.httpClient.patch(this.new_inventory_url + "/delete-inventory-image", body)
  }
  deleteWorkstationImage(id,body:any){
    return this.httpClient.post(this.new_workstation_url + "/remove-image/" + id, body)
  }
}
