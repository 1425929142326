import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InventoryService } from 'src/app/service/inventory.service';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass } from '@angular/common';
import { CrmService } from 'src/app/service/crm.service';
import { ForwardReqComponent } from "../shared/forward-req/forward-req.component";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NoDataComponent } from "../shared/no-data/no-data.component";
import { alertModalDecline, alertModalDelete, alertModalWithoutConfirm } from '../../helpers/alert';

interface Urgency {
  value: string;
  viewValue: string;
}

interface Request {
  value: string;
  viewValue: string;
}
interface newRequest {
  value: string;
  viewValue: string;
}
interface PendingServicesReq {
  value: string;
  viewValue: string;
}
interface ApprovedServicesReq {
  value: string;
  viewValue: string;
}

interface  DeclinedInventorysReq {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-new-inventory',
  standalone: true,
  imports: [MatInputModule, MatSelectModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, NgClass, ForwardReqComponent, NoDataComponent],
  templateUrl: './new-inventory.component.html',
  styleUrls: ["./new-inventory.component.css", "../styles/inventory.css"]
})
export class NewInventoryComponent {
  @ViewChild(ForwardReqComponent) forwardReqComponentRef!: ForwardReqComponent;
  
  newInventoryForm: FormGroup;
  forwardRequestForm: FormGroup;
  users: { value: string, viewValue: string, id: string }[] = [];

  urgencies: Urgency[] = [
    { value: 'High', viewValue: 'High' },
    { value: 'Medium', viewValue: 'Medium' },
    { value: 'Low', viewValue: 'Low' },
  ];

  requests: Request[] = [
    { value: 'New Inventory Request', viewValue: 'Forwarded Request' },
  ]

  newReq: newRequest[] = [
    { value: 'New Inventory Request', viewValue: 'New Inventory Request' }
  ]

  pendingServiceReq: PendingServicesReq[] = [
    { value: 'New Inventory Request', viewValue: 'Pending Request' }

  ]
  approvedServiceReq: ApprovedServicesReq[] = [
    { value: 'New Inventory Request', viewValue: 'Approved Request' }
  ]

  declinedInventoryReq: DeclinedInventorysReq[] = [
    { value: "Rejected", viewValue: "Declined Request" },
  ];

  constructor(private fb: FormBuilder, private inventoryService: InventoryService, private crmService: CrmService, private snackBar: MatSnackBar) {

    this.newInventoryForm = this.fb.group({
      assetCategory: ['', Validators.required],
      projectName: ['', Validators.required],
      startDate: ['', Validators.required],
      link1: ['', Validators.required],
      link2: ['', Validators.required],
      link3: ['', Validators.required],
      urgency: ['', Validators.required],
      approverName: ['', Validators.required]
    })

    // this.forwardRequestForm = this.fb.group({
    //   maximumCost: ['', Validators.required],
    //   comment: ['', Validators.required],
    //   link1: ['', Validators.required],
    //   link2: ['', Validators.required],
    //   link3: ['', Validators.required],
    //   selectedAdmin: ['', Validators.required]
    // })
  }
  submitted = false;
  userDepartment: string | null = null;
  showForwardButton = false;
  ngOnInit() {
    this.getAllUsers();
    this.getNewInventory();
    this.getAdminUsersList();
    this.getHRUsersList();
    this.userDepartment = localStorage.getItem('department');
    this.showForwardButton = this.userDepartment === 'HR';
  }

  // Reset Form
  openNewRequest() {
    this.newInventoryForm.reset();
    this.formSubmitted = false;
  }


  pendingReq = [];
  totalPending: any;
  currentRequestType: string = '';
  onFilterPendingRequest(requestType: string,pageNum: number = 1, limit: number = 20) {
    this.currentRequestType = requestType;
    const getPendingReqValue: any = {
      requestType: requestType,
    };
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.inventoryService.getPendingRequest(getPendingReqValue , field).subscribe(response => {
      console.log("Pending Request");
      this.pendingReq = response['data'].records;
      this.totalPending = response['data'].totalCounts
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = false;
      this.showApprovedRequest = false;
      this.showPendingRequest = true;
      this.showDeclinedRequest = false;
    })
  }

  approveInv = [];
  totalApproved: any;
  onFilterApprovedRequest(requestType: string,pageNum: number = 1, limit: number = 20) {
    this.currentRequestType = requestType;
    const getApprovedReqValue: any = {
      requestType: requestType,
    }
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.inventoryService.getApprovedRequest(getApprovedReqValue,field).subscribe(response => {
      console.log("Approved Request", response);
      this.approveInv = response['data'].records;
      this.totalApproved = response['data'].totalCounts;
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = false;
      this.showApprovedRequest = true;
      this.showPendingRequest = false;
      this.showDeclinedRequest = false;
    })
  }

  declineInv = [];
  totalDeclined: any;
  onFilterDeclinedRequest(
    pageNum: number = 1,
    limit: number = 20,
    status: string = "Rejected"
  ) {
    const field: any = {
      pageNum: pageNum,
      limit: limit,
      status: status,
    };
    this.inventoryService
      .getNewInventoryRequest(field)
      .subscribe((response) => {
        console.log("Approved Request", response);
        this.declineInv = response["data"].records;
        this.totalDeclined = response["data"].totalCounts;
        this.showApprovedRequest = false;
        this.showServiceRequestTable = false;
        this.showPendingRequest = false;
        this.showDeclinedRequest = true;
        this.showForwardedReqTable = false;
      });
  }
  showForwardedReqTable: boolean = false;
  showServiceRequestTable: boolean = true;
  showApprovedRequest: boolean = false;
  showPendingRequest: boolean = false;
  showDeclinedRequest: boolean = false;
  onRequestSelect(value: string): void {
    console.log("Selected value:", value);
    this.showForwardedReqTable = true; 
    this.showServiceRequestTable = false; 
    this.showApprovedRequest = false; 
    this.showPendingRequest = false; 
    this.showDeclinedRequest = false;
  
    this.filterForwardReq(value); 
  }

  forwardedReq = []
  totalForwarded: any;
  filterForwardReq(requestType: string, pageNum: number = 1, limit: number = 10) {
    this.currentRequestType = requestType;
    const getForwardReqValue: any = {
      requestType: requestType,
    };
    const field: any = {
      pageNum: pageNum,
      limit: limit,
    };
    this.inventoryService.getAllForwardedReq(getForwardReqValue, field).subscribe(response => {
      console.log("Forward Response", response);
      this.forwardedReq = response['data'].records;
      this.totalForwarded = response['data'].totalCounts;
      this.showForwardedReqTable = true;
      this.showServiceRequestTable = false;
      this.showApprovedRequest = false; 
      this.showPendingRequest = false; 
      this.showDeclinedRequest = false;
      // this.getNewInventory();
    });
  }


  adminUsers: { value: string, viewValue: string, id: string }[] = [];
  getAdminUsersList() {
    const department = "ADMIN";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.adminUsers = data.map((adminUser) => {
          return {
            value: adminUser.userName,
            viewValue: adminUser.userName,
            id: adminUser._id
          };
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  hrUsers: { value: string; viewValue: string; id: string }[] = [];
  getHRUsersList(){
    const department = "HR";
    const loggedInUsername = localStorage.getItem("username");
    const loggedInUserId = localStorage.getItem("guestID");
    this.crmService.getAnyUsers(department).subscribe(
      (res) => {
        let data = res["data"];
        this.hrUsers = data.map((hrUser: any) => {
          return {
            value: hrUser.userName,
            viewValue: hrUser.userName,
            id: hrUser._id,
          };
        });
        console.log("users=====", this.hrUsers);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllUsers(): void {
    this.inventoryService.getInvUsers().subscribe(res => {
      let data = res["data"];
      this.users = data.map((user: any) => ({
        value: user.userName,
        viewValue: user.userName,
        id: user._id
      }));
      console.log("users", this.users);

    });
  }

  // Shimmer Loader
  isShimmerLoading: boolean = true;
  shimmerLoadingValue = ([] = [1, 2, 3, 4 , 5]);


  //Formatted Start DATE
  formatStartDate(date: Date): { day: number; month: number; year: number } {
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };
  }
  formSubmitted = false;
  onSubmitForm() {
    console.log(this.newInventoryForm.value);

    if (this.newInventoryForm.invalid) {
      console.log("Form is invalid");
      return alertModalWithoutConfirm(
        "warning",
        "Please fill in all required fields before submitting."
        );
        }
        
    const newInventoryValues = {
      ...this.newInventoryForm.value
    };

    const startDate = new Date(newInventoryValues.startDate);
    const formattedStartDate = this.formatStartDate(startDate);

    const approver = [...this.adminUsers , ...this.hrUsers].find(user => user.value === newInventoryValues.approverName);
    console.log('approver:', approver);
    const approverId = approver ? approver.id : "null";
    console.log({ approverId });

    const linksArray = [
      newInventoryValues.link1,
      newInventoryValues.link2,
      newInventoryValues.link3
    ].filter(link => link);

    const getNewInventoryFormValue: any = {
      assetCategory: String(newInventoryValues.assetCategory),
      projectName: String(newInventoryValues.projectName),
      startDate: {
        day: startDate.getDate(),
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear()
      },
      links: linksArray,
      urgency: newInventoryValues.urgency,
      approverName: String(newInventoryValues.approverName),
      approverId: approverId
    };


    this.inventoryService.newInventoryRequest(getNewInventoryFormValue).subscribe(response => {
      console.log('submitted ', response);
      this.formSubmitted = true;
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = true;
      this.getNewInventory()

    }, error => {
      console.log('Error', error);
    });
  }



  selectedInventory: any = {};
  newInvReqId: any = {};
  newInventories = []
  totalNewInventory: any;
  searchQuery: string;
  getNewInventory() {
    const field: any = {
      pageNum: 1,
      limit: 20,
      searchQuery: this.searchQuery,
    }
    console.log("loggged")
    this.inventoryService.getNewInventoryRequest(field).subscribe(response => {
      console.log("Response", response);
      this.newInventories = response['data'].records
      this.totalNewInventory = response['data'].totalCounts
      console.log(this.newInventories)
      this.showForwardedReqTable = false;
      this.showServiceRequestTable = true;
      this.isShimmerLoading = false;
    })
  }

  clearSearch() {
    this.searchQuery = "";
    this.getNewInventory();
  }

  openForward(requestId: any) {
    console.log("Request ID", requestId)
    // this.selectedInventory = null; 
    this.newInvReqId = requestId; 
    const forwardReqComponent = this.forwardReqComponentRef;
  if (forwardReqComponent) {
    forwardReqComponent.forwardReqReset(); 
  }
  }
  acceptRequest(requestId: any) {
    const acceptRequest = {
      action: 'Accept',
      requestId: requestId,
      requestType: 'New Inventory Request',
    }
    this.inventoryService.adminAction(acceptRequest).subscribe(response => {
      console.log("called");
      this.snackBar.open("Accepted", "OK");

      this.getNewInventory();
    })
  }


    async declineRequest(requestId: any) {
      const userConfirmed = await alertModalDecline(
        "Are you sure you want to decline this request?"
      );
    if(userConfirmed){
    const declineRequest = {
      action: 'Rejected',
      requestId: requestId,
      requestType: 'New Inventory Request',
    }
    this.inventoryService.adminAction(declineRequest).subscribe(response => {
      console.log("called")
      this.getNewInventory();
    },
  (err) => {
            console.error("Error while declining the request", err);
            alertModalWithoutConfirm("error", "Failed to decline the request.");
          }
        );
      } else {
        console.log("User cancelled the decline action");
  }
    }


    async deletenewInventoryRequest(requestId: any){
      const userConfirmed = await alertModalDelete(
        "Are you sure you want to delete this request?"
      );
      if (userConfirmed) {
        const declineRequest = {
          action: "Rejected",
          requestId: requestId,
          requestType: 'New Inventory Request',
        };
    
        this.inventoryService.adminAction(declineRequest).subscribe(
          (response) => {
            // this.snackBar.open("Request Declined", "OK");
            this.getNewInventory();
          },
          (err) => {
            alertModalWithoutConfirm("error", "Failed to delete the request.");
          }
        );
      }
    }
  currentPage = 1;
  itemsPerPage = 20;
  getPaginatedLeads() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if(this.showServiceRequestTable){
      return this.newInventories.slice(startIndex, endIndex);
    }
    if(this.showForwardedReqTable){
      return this.forwardedReq.slice(startIndex, endIndex);
      }
    if(this.showApprovedRequest){
      return this.approveInv.slice(startIndex, endIndex);
    }
    if(this.showPendingRequest){
      return this.pendingReq.slice(startIndex, endIndex);
    }
    if(this.showDeclinedRequest){
      return this.declineInv.slice(startIndex, endIndex);
      }
  }

  pageChanged(page: number) {
    this.currentPage = page;
    console.log(page, "PAGE");
    const option : any= {
      pageNum: page,
      limit: 20,
    };
    if(this.showDeclinedRequest){
      option.status = "Rejected";
      option.pageNum = page;
      option.limit = 20;
    }
        if(this.showServiceRequestTable){
      this.inventoryService.getServiceRequest(option).subscribe(res => {
        this.newInventories = res['data'].records
        this.totalNewInventory = res['data'].totalCounts
        this.isShimmerLoading = false;
      }
    );
  }
  if(this.showForwardedReqTable){
    this.inventoryService.getAllForwardedReq(this.currentRequestType, option).subscribe(res => {
      this.forwardedReq = res['data'].records;
      this.totalForwarded = res['data'].totalCounts;
      this.isShimmerLoading = false;
    })
  }
  if(this.showApprovedRequest){
    this.inventoryService.getApprovedRequest(this.currentRequestType, option).subscribe(res => {
      this.approveInv = res['data'].records;
      this.totalApproved = res['data'].totalCounts;
      this.isShimmerLoading = false;
    })
  }
  if(this.showPendingRequest){
    this.inventoryService.getPendingRequest(this.currentRequestType, option).subscribe(res => {
      this.pendingReq = res['data'].records;
      this.totalPending = res['data'].totalCounts
      this.isShimmerLoading = false;
    });
    if(this.showDeclinedRequest){
      this.inventoryService.getNewInventoryRequest(option).subscribe(res => {
        this.declineInv = res['data'].records;
        this.totalDeclined = res['data'].totalCounts;
        this.isShimmerLoading = false;
      });
    }
  }
}

  get totalPages(): number {
    if(this.showServiceRequestTable){
    return Math.ceil(this.totalNewInventory / this.itemsPerPage);
  }
  if(this.showForwardedReqTable){
    return Math.ceil(this.totalForwarded / this.itemsPerPage);
  }
  if(this.showApprovedRequest){
    return Math.ceil(this.totalApproved / this.itemsPerPage)
  }
  if(this.showPendingRequest){
    return Math.ceil(this.totalPending / this.itemsPerPage)
  }
  if(this.showDeclinedRequest){
    return Math.ceil(this.totalDeclined / this.itemsPerPage)
    }
}

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, index) => index + 1);
  }

  trackByFn(index: number, item: any): any {
    return item._id;
  }

  get visiblePages(): number[] {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index,
    );
  }

  isPageVisible(page: number): boolean {
    const numVisiblePages = 3;
    const startPage = Math.max(
      1,
      this.currentPage - Math.floor(numVisiblePages / 2),
    );
    const endPage = Math.min(this.totalPages, startPage + numVisiblePages - 1);

    return page <= 3 || (page >= startPage && page <= endPage);
  }

}






